<template lang="pug">
.organization-selector
  .background(:style="background")
  .content
    img.logo(:src="logo")
    .inner-content
      .titles(v-if="!loadingLogout")
        h1 {{ $t('organization_selector.title') }}
        p {{ $t('organization_selector.description') }}
      .loading-in-modal(v-if="loadingLogout")
        fp-loading
      .organizations-container(v-else)
        fpui-input-search(
          v-if="organizations.length > 3"
          :key-id="'search-organization'"
          v-model="search"
        )
        .loading-in-modal(v-if="loading")
          fp-loading
        .organizations-list(v-else)
          a.organization-item(
            v-for="organization in pagedOrganizations"
            @click="$store.commit('SET_ACTIVE_ORGANIZATION', organization._id)"
            :href="organizationLink(organization)"
          )
            fpui-canvas-icon(
              :name="organization.name"
              :size="50"
              font-size="22px"
              :letterNumber="2"
              color="white"
              :background-color="getColor(organization.name)"
            )
            .organization-informations-container
              .organization-informations
                .organization-name {{ organization.name }}
                .organization-date {{ createdOn(organization.created_at) }}
                //- .organization-users
                //-   .users-icons
                //-     fpui-canvas-icon(
                //-       v-for="(user, idx) in organizationUsers(organization)"
                //-       :key="idx"
                //-       :name="user.email"
                //-       :size="18"
                //-       :letterNumber="2"
                //-       color="white"
                //-       :background-color="getColor(user.email)"
                //-       type="circle"
                //-     )
                //-   .users-count {{ $t('organization_selector.members', [organization.users.length], organization.users.length) }}
              .access-organization
                i.fp4.fp4-arrow-right
          fp-pagination(
            :key='organizationsFiltered.length'
            :items='organizationsFiltered'
            @page-changed="(page) => pagedOrganizations = page.items"
          )
      .organization-selector-footer
        div {{ $t('organization_selector.dont_use_organizations') }}
        .create-or-logout(v-tooltip="disabledOrganizationCreation")
          router-link(
            :class='{ disabled: !canCreateOrganization }'
            :to="newOrganizationLink"
          ) {{ $t('organization_selector.create_organization') }}
          div {{ $t('organization_selector.or') }}
          a(@click="logout") {{ $t('organization_selector.logout') }}
</template>

<script>
import Config from '@/shared/Config'
import moment from 'moment'
import backgroundTexture from '@/shared/assets/img/login_background.svg'

export default {
  data () {
    return {
      config: null,
      search: '',
      loading: true,
      loadingLogout: false,
      pagedOrganizations: []
    }
  },
  computed: {
    organizations () {
      return this.$store.getters.ORGANIZATIONS || []
    },
    organizationsFiltered () {
      if (this.search !== '') {
        return this.organizations.filter(o => {
          return o.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
        })
      }
      return this.organizations
    },
    background () {
      return {
        background: `url(${backgroundTexture}) no-repeat, linear-gradient(180deg, #035599 0%, #00B8E0 54.17%, #035599 100%) no-repeat, radial-gradient(59.98% 30.76% at 29.67% 50%, rgba(255, 255, 255, 0.00) 0%, rgba(217, 217, 217, 0.22) 100%)`,
        'background-size': 'cover'
      }
    },
    logo () {
      const defaultLogo = require('@/shared/assets/img/ovhcloud_dataplatform_logo.svg')
      return this.$store.getters.PREFERENCES?.logo || defaultLogo || ''
    },
    newOrganizationLink () {
      return '/home/new'
    },
    canCreateOrganization () {
      return this.config?.CREATE_ORGANIZATION
    },
    disabledOrganizationCreation () {
      if (!this.canCreateOrganization) return { content: this.$t('organization.creation.no_access'), delay: 0 }
      return null
    }
  },
  async mounted () {
    this.config = await Config()
    this.loading = false
  },
  methods: {
    getColor (txt) {
      const colors = ['#FFCD2F', '#f62172', '#0050d7', '#81cc28']
      return colors[txt.length % colors.length]
    },
    organizationLink (organization) {
      return `/#/home/${organization.id}/`
    },
    organizationUsers (organization) {
      return organization?.users?.slice(0, 4)
    },
    logout () {
      this.loadingLogout = true
      this.$store.commit('SET_OPEN_SIDE_BAR', false)
      this.$store.dispatch('LOGOUT_CMP')
    },
    createdOn (createdAt) {
      if (!createdAt) return ''
      const d = moment(createdAt).locale(this.$i18n.locale()).format('Do MMMM YYYY')
      return this.$t('organization_selector.created_at', [d])
    }
  }
}
</script>

<style lang="less">
.organization-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #035599;
  > .background {
    position: absolute;
    width: 100vw;
    height: 100vh;
    bottom: 0;
    z-index: 0;
  }
  .content {
    z-index: 1;
    border-radius: 10px;
    border: 0.5px solid #E4E7EC;
    background: #FFF;
    box-shadow: 0px 2px 4px 0px rgba(52, 65, 78, 0.05);
    width: 558px;
    padding: 24px;
    margin-top: -60px; // Remove OrganizationSelectorHeader.vue height size
    .inner-content {
      h1 {
        color: @text-color;
        font-size: 38px;
        font-weight: 600;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      > p {
        color: #748294;
        font-size: 16px;
        font-weight: 400;
      }
      .loading-in-modal {
        width: 100%;
        text-align: center;
      }
      .organizations-container {
        padding: 10px 20px;
        .fpui-input-search {
          margin-bottom: 20px;
        }
        .organizations-list {
          overflow-y: auto;
          a.organization-item {
            display: flex;
            gap: 10px;
            text-decoration: none;
            padding: 10px 0;
            &:hover {
              background-color: #fafafb;
            }
            &:not(:last-child) {
              border-bottom: 1px solid #E4E7EC;
            }
            .organization-informations-container {
              display: flex;
              flex: 0 1 100%;
              justify-content: space-between;
              align-items: center;
              .organization-informations {
                display: flex;
                flex-direction: column;
                .organization-name {
                  color: #3E4550;
                  font-size: 13px;
                  font-weight: 600;
                }
                .organization-date {
                  color: @grey;
                }
                .organization-users {
                  display: flex;
                  align-items: center;
                  .users-icons {
                    display: flex;
                    flex-direction: row-reverse;
                    .fpui-canvas-icon {
                      &:not(:first-child) {
                        margin-right: -3px;
                      }
                      canvas {
                        border: 1.5px solid white;
                      }
                    }
                  }
                  .users-count {
                    color: #748294;
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 20px;
                    margin-left: 5px;
                  }
                }
              }
              .access-organization {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 40px;
                color: #97a6b7;
              }
            }
          }
        }
      }
      .organization-selector-footer {
        text-align: center;
        padding: 10px;
        color: #B2BECA;
        font-size: 12px;
        font-weight: 400;
        .create-or-logout {
          padding-top: 10px;
          display: flex;
          justify-content: center;
          gap: 5px;
          a {
            &.disabled {
              opacity: 0.5;
              pointer-events: none;
            }
          }
        }
        a {
          cursor: pointer;
          color: @blue;
          font-weight: 600;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .logo {
    width: 150px;
  }
}
</style>
