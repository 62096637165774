<template lang="pug">
.profile-organizations.fp-container
  fp-loader(v-if='innerLoading')
  fp-loader(v-if='loading')
  .organizations(v-else)
    .organizations-container.section.margin-bottom
      .title-container
        .title {{ $t('profile.organizations.invitations.title') }}
        fpui-helper(:text="$t('profile.preferences.related_organizations.tooltip')")
      .organizations-inner(v-if='organizationInvitations.length')
        fpui-input-search(
          :key-id="'search-invitations'"
          v-model="search.invitations"
        )
        .organizations-table(:class="{ empty: !paged.invitations.length }")
          .organization-item(
            v-for="organization in paged.invitations"
          )
            fpui-canvas-icon(
              :name="organization.name"
              :size="50"
              font-size="22px"
              :letterNumber="2"
              color="white"
              :background-color="getColor(organization.name)"
            )
            .organization-informations-container
              .organization-informations
                .organization-name {{ organization.name }}
                .organization-users(v-if='organization.users && organization.users.length')
                  .users-icons
                    fpui-canvas-icon(
                      v-for="(user, idx) in organizationUsers(organization)"
                      :key="idx"
                      :name="user.email"
                      :size="18"
                      :letterNumber="2"
                      color="white"
                      :background-color="getColor(user.email)"
                      type="circle"
                    )
                  .users-count {{ $t('organization_selector.members', [organization.users.length], organization.users.length) }}
              .organization-actions
                fpui-button(
                  color='blue-flash'
                  auto-width
                  @click="acceptInvitation(organization)"
                ) {{ $t('profile.organizations.invitations.join') }}
                fpui-button(
                  color='white'
                  auto-width
                  @click="discardInvitation(organization)"
                ) {{ $t('profile.organizations.invitations.discard') }}
          fp-pagination(
            :key='organizationsInvitationsFiltered.length'
            :items='organizationsInvitationsFiltered'
            @page-changed="(page) => paged.invitations = page.items"
          )
        .organizations-empty-paged(v-if='!paged.invitations.length')
          span {{ $t('profile.preferences.invites_received_no_search') }}
      .organizations-inner.empty(v-else)
        span {{ $t('profile.preferences.invites_received_no_date') }}

    .organizations-container.section
      .title-container
        .title {{ $t('profile.organizations.available.title') }}
        fpui-helper(:text="$t('profile.preferences.related_organizations.tooltip')")
      .organizations-inner(v-if='organizationAvailable.length')
        fpui-input-search(
          :key-id="'search-available'"
          v-model="search.available"
        )
        .organizations-table(:class="{ empty: !paged.available.length }")
          .organization-item(
            v-for="organization in paged.available"
          )
            fpui-canvas-icon(
              :name="organization.name"
              :size="50"
              font-size="22px"
              :letterNumber="2"
              color="white"
              :background-color="getColor(organization.name)"
            )
            .organization-informations-container
              .organization-informations
                .organization-name {{ organization.name }}
                .organization-users(v-if='organization.users && organization.users.length')
                  .users-icons
                    fpui-canvas-icon(
                      v-for="(user, idx) in organizationUsers(organization)"
                      :key="idx"
                      :name="user.email"
                      :size="18"
                      :letterNumber="2"
                      color="white"
                      :background-color="getColor(user.email)"
                      type="circle"
                    )
                  .users-count {{ $t('organization_selector.members', [organization.users.length], organization.users.length) }}
              .organization-actions
                fpui-button(
                  v-if='organization.join_request_active'
                  color='blue-flash'
                  auto-width
                  @click="requestToJoin(organization)"
                ) {{ $t('profile.preferences.related_organizations.request') }}
                fpui-button(
                  v-if='organization.pending_request'
                  color='white'
                  auto-width
                  @click="cancelRequest(organization)"
                ) {{ $t('button.cancel') }}
                fpui-button(
                  v-if='organization.pending_request'
                  color='blue-flash'
                  auto-width
                  @click="requestToJoin(organization, true)"
                ) {{ $t('profile.preferences.invites_pending.resend') }}
          fp-pagination(
            :key='organizationsAvailableFiltered.length'
            :items='organizationsAvailableFiltered'
            @page-changed="(page) => paged.available = page.items"
          )
        .organizations-empty-paged(v-if='!paged.available.length')
          span {{ $t('profile.preferences.related_organizations_no_search') }}
      .organizations-inner.empty(v-else)
        span {{ $t('profile.preferences.related_organizations_no_date') }}
</template>

<script>
import TermsConditionsModal from '@/shared/components/modals/TermsConditions'

export default {
  props: {
    user: { type: Object, required: true }
  },
  data () {
    return {
      loading: true,
      innerLoading: false,
      search: {
        invitations: '',
        available: ''
      },
      paged: {
        invitations: [],
        available: []
      }
    }
  },
  computed: {
    organizationInvitations () {
      return this.$store.getters.INVITING_ORGANIZATIONS || []
    },
    organizationsInvitationsFiltered () {
      if (this.search.invitations !== '') {
        return this.organizationInvitations.filter(o => {
          return o.name.toLowerCase().indexOf(this.search.invitations.toLowerCase()) !== -1
        })
      }
      return this.organizationInvitations
    },
    organizationAvailable () {
      return [].concat(...[
        this.$store.getters.REQUESTED_ORGANIZATIONS,
        this.$store.getters.AVAILABLE_ORGANIZATIONS
      ]).sort((a, b) => (a.name > b.name) ? 1 : -1)
    },
    organizationsAvailableFiltered () {
      if (this.search.available !== '') {
        return this.organizationAvailable.filter(o => {
          return o.name.toLowerCase().indexOf(this.search.available.toLowerCase()) !== -1
        })
      }
      return this.organizationAvailable
    },
    currentUser () {
      return this.$store.getters.SESSION
    }
  },
  async mounted () {
    if (!this.$store.getters.AVAILABLE_ORGANIZATIONS.length) await this.$store.dispatch('LOAD_AVAILABLE_ORGANIZATIONS')
    if (!this.$store.getters.INVITING_ORGANIZATIONS.length) await this.$store.dispatch('LOAD_INVITING_ORGANIZATIONS')
    if (!this.$store.getters.REQUESTED_ORGANIZATIONS.length) await this.$store.dispatch('LOAD_REQUESTED_ORGANIZATIONS')
    this.loading = false
  },
  methods: {
    getColor (txt) {
      const colors = ['#FFCD2F', '#f62172', '#0050d7', '#81cc28']
      return colors[txt.length % colors.length]
    },
    organizationUsers (organization) {
      return organization?.users?.slice(0, 4)
    },
    async requestToJoin (organization, resend = false) {
      this.innerLoading = true
      if (!this.currentUser?.tc_accepted_at) {
        try {
          if (await TermsConditionsModal(this)) {
            await this.$store.dispatch('ACCEPT_TERMS_AND_CONDITIONS')
          } else {
            this.innerLoading = false
            return this.$fpuiMessageBlock.info(this.$t('fpui-modal.tc.must_accept'))
          }
        } catch (error) {
          this.$fpuiMessageBlock.error(error?.message)
        }
      }
      try {
        await organization.addRequest()

        const messageTracking = resend ? 'Resend request access to an organization' : 'Request access to an organization'
        this.$analytics.track(messageTracking, {})
        this.$fpuiMessageBlock.pop('success', resend ? this.$t('profile.preferences.invites_pending.resend.sucess') : this.$t('profile.preferences.related_organizations.request.success'))
      } catch (err) {
        this.$fpuiMessageBlock.pop('error', err.message)
      } finally {
        if (!resend) {
          await this.$store.dispatch('LOAD_AVAILABLE_ORGANIZATIONS')
          await this.$store.dispatch('LOAD_ORGANIZATIONS', true)
        }
        this.innerLoading = false
      }
    },
    async cancelRequest (organization) {
      this.innerLoading = true
      try {
        await organization.deleteJoin(this.currentUser)
      } catch (err) {
        this.$fpuiMessageBlock.pop('error', err.message)
      } finally {
        await this.$store.dispatch('LOAD_AVAILABLE_ORGANIZATIONS')
        await this.$store.dispatch('LOAD_REQUESTED_ORGANIZATIONS')
        this.innerLoading = false
      }
    },
    async acceptInvitation (organization) {
      this.innerLoading = true
      try {
        await this.$api.ORGANIZATION.organizations.userJoinsFromProfile(organization._id)
        this.$fpuiMessageBlock.pop('success', this.$t('profile.organizations.invitations.join.success'))
        await this.$store.dispatch('LOAD_ORGANIZATIONS', true)
        // If no organization active, we set it when request so the user can see the status in the selector
        this.$store.commit('REMOVE_INVITING_ORGANIZATION', organization._id)
      } catch (err) {
        this.$fpuiMessageBlock.pop('error', err.message)
        console.error(err)
      } finally {
        this.innerLoading = false
      }
    },
    async discardInvitation (organization) {
      this.innerLoading = true
      try {
        await this.$api.ORGANIZATION.organizations.userRejectJoinFromProfile(organization._id)
        this.$store.commit('REMOVE_INVITING_ORGANIZATION', organization._id)
        await this.$store.dispatch('LOAD_AVAILABLE_ORGANIZATIONS')
      } catch (err) {
        this.$fpuiMessageBlock.pop('error', err.message)
        console.error(err)
      } finally {
        this.innerLoading = false
      }
    }
  }
}
</script>

<style lang="less">
.profile-organizations {
  display: flex;
  flex-direction: column;
  padding: 25px 15px;
  width: 100%;
  .title-container {
    display: flex;
    margin: 15px 0;
    .title {
      font-size: 12px;
      color: @blue;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
  .margin-bottom {
    margin-bottom: 10px;
  }
  .section {
    padding: 0px 20px 15px 20px;
  }
  .organizations-container {
    .organizations-inner {
      .organizations-table {
        margin-top: 15px;
        border-radius: 5px;
        &:not(.empty) {
          border: 1px solid #E4E7EC;
        }
        .organization-item {
          display: flex;
          gap: 10px;
          text-decoration: none;
          padding: 10px 20px;
          &:hover {
            background-color: #fafafb;
          }
          &:not(:nth-last-child(-n + 2)) {
            border-bottom: 1px solid #E4E7EC;
          }
          .organization-informations-container {
            display: flex;
            flex: 0 1 100%;
            justify-content: space-between;
            align-items: center;
            .organization-informations {
              display: flex;
              flex-direction: column;
              .organization-name {
                color: #3E4550;
                font-size: 13px;
                font-weight: 600;
              }
              .organization-users {
                display: flex;
                align-items: center;
                .users-icons {
                  display: flex;
                  flex-direction: row-reverse;
                  .fpui-canvas-icon {
                    &:not(:first-child) {
                      margin-right: -3px;
                    }
                    canvas {
                      border: 1.5px solid white;
                    }
                  }
                }
                .users-count {
                  color: #748294;
                  font-size: 10px;
                  font-weight: 400;
                  line-height: 20px;
                  margin-left: 5px;
                }
              }
            }
            .organization-actions {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: center;
              align-items: center;
              color: #97a6b7;
              gap: 11px;
            }
          }
        }
      }
      .fp-pagination {
        padding: 10px 0;
        border-top: 1px solid #E4E7EC;
      }
      &.empty {
        > span {
          color: #B2BECA;
          font-size: 12px;
        }
      }
      > .organizations-empty-paged {
        color: #B2BECA;
        font-size: 12px;
        text-align: center;
      }
    }
  }
}
</style>

