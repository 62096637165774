<template lang="pug">
  .profile
    fp-loader(v-if="loading")
    .profile-header
      fp-title(:parent="userName")
      fp-loading(v-if="user.saving")
    fpui-tabs(ref='tabs')
      fpui-tab-if(:header="$t('profile.tab.preferences')")
        preferences(
          :key="loading"
          :user="user"
          @load-orgs="() => loadOrganizations()"
        )
      fpui-tab-if(:header="$t('profile.tab.security')")
        security(:user="user" @load-orgs="() => loadOrganizations()")
      fpui-tab-if(:header="$t('profile.tab.organizations')")
        organizations(:user="user")
</template>

<script>
import Preferences from './Preferences'
import Security from './Security'
import Organizations from './Organizations'
import _debounce from 'lodash/debounce'

export default {
  components: {
    Preferences,
    Security,
    Organizations
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    userName () {
      return `${this.user.firstname} ${this.user.lastname}`
    },
    user () {
      return this.$store.getters.SESSION
    }
  },
  mounted () {
    this.$store.commit('SET_OPEN_SIDE_BAR', false)
    if (this.$route.query?.tab) {
      switch (this.$route.query?.tab) {
        case 'preferences':
          this.$refs.tabs.setActive(0)
          break
        case 'security':
          this.$refs.tabs.setActive(1)
          break
        case 'organizations':
          this.$refs.tabs.setActive(2)
          break
      }
    }
  },
  methods: {
    loadOrganizations: _debounce(function () {
      this.$store.dispatch('LOAD_ORGANIZATIONS', true)
    }, 1000)
  }
}
</script>

<style lang="less">
.profile {
  margin-left: 84px;
  margin-right: 83px;
  box-shadow: 0 3px 13px 0 rgb(151 167 183 / 30%);
  background-color: white;
  height: calc(~"100% - 4px");
  overflow-y: hidden;
  overflow-x: hidden;
  .profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title-container {
      padding-left: 60px;
    }
    .fp-loading {
      margin-right: 30px;
    }
  }

  .fpui-tabs {
    height: 100%;
    .section{
      background-color: #FFFFFF;
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      border: 0.5px solid #E9ECF0;
      box-shadow: 0px 2px 4px 0px rgba(52, 65, 78, 0.05);
    }

    .fpui-tabs-contents {
      overflow: auto;
      background-color: #F6F9FC;
      height: calc(~"100% - 116px");
    }
  }
}
</style>
