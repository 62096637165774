import TermsConditions from './TermsConditions.vue'

export default (ctx) => {
  return new Promise((resolve) => {
    ctx.$modal.show(TermsConditions, { resolve }, {
      height: 'auto',
      width: '603px'
    }, {
      closed: () => {
        return resolve(false)
      }
    })
  })
}
