<template lang="pug">
  .security.fp-container
    .section
      .title.title-margin {{ $t('profile.security.update_password') }}
      .password-reset-container
        form
          .line
            .old-password(v-if="user.has_password")
              fpui-input-password(
                :label="$t('profile.security.oldpassword')"
                :placeholder="$t('profile.security.oldpassword')"
                v-model="password.oldpassword"
              )
            //- .link(@click="forgetPassword") {{ $t('profile.security.forget') }}
          .line
            fpui-input-password-strength(
              :placeholder="$t('profile.security.password')"
              v-model="password.password"
              :helper-title="$t('authentication.password.helper.title')"
              :helper="passwordHelper"
              :error="!!isValidPassword.length && !!password.password.length"
              :error-message="$t('authentication.password.error')"
              :class="{ 'error': !isValidPassword }"
            )
              template(
                v-slot:label
              )
                label {{ $t('profile.security.password') }}
          fpui-input-password(
            :label="$t('profile.security.repeat_password')"
            :placeholder="$t('profile.security.repeat_password')"
            v-model="password.repeatpassword"
            :error="password.password !== password.repeatpassword ? true : false"
          )
          .error-message(v-if="password.password !== password.repeatpassword") {{ $t('profile.security.password_error') }}
          .button-container
            fpui-button(
              color='green'
              v-if="!isPasswordEmpty"
              :disabled="hasPasswordError || !!isValidPassword.length || user.has_password && !password.oldpassword"
              @click="resetPassword"
              auto-width
            ) {{ $t('button.update') }}

      .linked-account
        .title.title-margin {{ $t('profile.security.linked_account') }}
        fpui-table(
          :columns='authentications.columns'
          :actions='authentications.actions'
          :data='authentications.data'
        )
        .link-account-container()
          .provider(v-for="provider in restProviders")
            img(@click="linkAccount(provider)" :src='providerIcons[provider.provider]')

    .recent-session.section
      .title.title-margin {{ $t('profile.security.recent_session') }}
      fpui-table(
        :columns='recentSession.columns'
        :actions='recentSession.actions'
        :data='recentSession.data'
        sortOn="last_connection"
        :pagination='{ perPage: 9 }'
      )

    .section
      .mfa
        multi-factor-authentication(@load-orgs="$emit('load-orgs')")
</template>
<script>
import CellImageAndText from './CellImageAndText'
import MultiFactorAuthentication from './MultiFactorAuthentication'
import Config from '@/shared/Config'
import ConfirmModal from '@/shared/components/modals/Confirm'
import UAParser from 'ua-parser-js'
import moment from 'moment'
import qs from 'qs'
export default {
  components: { MultiFactorAuthentication },
  props: {
    user: { type: Object, required: true }
  },
  data () {
    return {
      password: {
        oldpassword: '',
        password: '',
        repeatpassword: ''
      },
      authenticationsList: [],
      providerIcons: {
        openid_azure: require('@/core/assets/img/oauth/azure.svg'),
        openid_ovh: require('@/core/assets/img/oauth/ovh.png'),
        openid_bitbucket: require('@/core/assets/img/oauth/bitbucket.svg'),
        openid_github: require('@/core/assets/img/oauth/github.svg'),
        openid_gitlab: require('@/core/assets/img/oauth/gitlab.svg'),
        openid_google: require('@/core/assets/img/oauth/google.svg')
      },
      listSession: [],
      preferences: {}
    }
  },
  computed: {
    hasPasswordError () {
      if (this.isPasswordEmpty) return false
      return this.password.repeatpassword === '' || this.password.password === '' || this.password.repeatpassword !== this.password.password
    },
    passwordPolicies () {
      return {
        nb_char: parseInt(this.preferences.password_policies?.nb_char),
        nb_int: parseInt(this.preferences.password_policies?.nb_int),
        nb_schar: parseInt(this.preferences.password_policies?.nb_schar),
        nb_cchar: parseInt(this.preferences.password_policies?.nb_cchar)
      }
    },
    isValidPassword () {
      const errors = []
      if (this.password.password.length < this.passwordPolicies.nb_char) errors.push('not_enough_char')
      if (this.nbInt < this.passwordPolicies.nb_int) errors.push('not_enough_number')
      if (this.nbCaps < this.passwordPolicies.nb_cchar) errors.push('not_enough_caps')
      if (this.nbSpecial < this.passwordPolicies.nb_schar) errors.push('not_enough_special_char')
      return errors
    },
    passwordHelper () {
      return `<div>
        <ul class="password-helper-content">
          ${this.passwordPolicies.nb_char ? `<li><i class="fp4 ${this.icon(this.password.password.length < this.passwordPolicies.nb_char)}"></i><span>${this.$t('authentication.password.helper.nbChar_minimum', [this.passwordPolicies.nb_char], this.passwordPolicies.nb_char)}</span></li>` : ''}
          ${this.passwordPolicies.nb_char ? `<li><i class="fp4 ${this.icon(this.nbCaps < this.passwordPolicies.nb_cchar)}"></i><span>${this.$t('authentication.password.helper.nbUpperCaseChar_minimum', [this.passwordPolicies.nb_cchar], this.passwordPolicies.nb_cchar)}</span></li>` : ''}
          ${this.passwordPolicies.nb_int ? `<li><i class="fp4 ${this.icon(this.nbInt < this.passwordPolicies.nb_int)}"></i><span>${this.$t('authentication.password.helper.nbNumber_minimum', [this.passwordPolicies.nb_int], this.passwordPolicies.nb_int)}</span></li>` : ''}
          ${this.passwordPolicies.nb_schar ? `<li><i class="fp4 ${this.icon(this.nbSpecial < this.passwordPolicies.nb_schar)}"></i><span>${this.$t('authentication.password.helper.nbSpecial_minimum', [this.passwordPolicies.nb_schar], this.passwordPolicies.nb_schar)}</span></li>` : ''}
        </ul>
      </div>`
    },
    nbInt () {
      let number = 0
      for (const i in this.password.password) {
        if (!isNaN(this.password.password.charAt(i))) number++
      }
      return number
    },
    nbSpecial () {
      let number = 0
      const specialChars = '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~'
      for (const i in this.password.password) {
        if (specialChars.indexOf(this.password.password.charAt(i)) !== -1) number++
      }
      return number
    },
    nbCaps () {
      let number = 0
      for (const i in this.password.password) {
        if (/[A-Z]/.test(this.password.password.charAt(i))) number++
      }
      return number
    },
    isPasswordEmpty () {
      if (this.user.has_password) {
        if (this.password.repeatpassword === '' && this.password.password === '' && this.password.oldpassword === '') return true
        return false
      } else {
        if (this.password.repeatpassword === '' && this.password.password === '') return true
        return false
      }
    },
    restProviders () {
      return this.authenticationsList.filter(a => {
        return !this.user.authentication_providers.find(ap => ap.id === a._id)
      })
    },
    authentications () {
      return {
        data: this.user.authentication_providers.filter(row => {
          const auth = this.authenticationsList.find(a => {
            return a._id === row.id
          })
          if (auth?.provider === 'password') return false
          return true
        }),
        actions: [{
          id: 'delete',
          icon: 'fp4 fp4-trash-can',
          click: async (row) => {
            const auth = this.authenticationsList.find(auth => auth._id === row.id)
            const confirmResult = await ConfirmModal(this, {
              contentHtml: true,
              content: `<span style="font-size:14px">${this.$t('treeview.remove.modal.head')} <span style="font-weight:600"> ${auth.provider}</span>${this.$t('treeview.remove.modal.question_mark')}<br />${this.$t('treeview.remove.modal.tail')} </span><br>`,
              title: this.$t('profile.security.unlink'),
              yes: {
                text: this.$t('fpui-modal-confirm.remove'),
                color: 'red'
              }
            })
            if (!confirmResult) return
            await this.$api.KING.authentications.revoke(row.id)
            const authenticationProviders = this.user.authentication_providers.filter(r => r.id !== row.id)
            this.user.update('authentication_providers', authenticationProviders, true)
            this.$emit('load-orgs')
          }
        }],
        columns: [{
          name: 'authentication',
          label: this.$t('profile.security.application'),
          align: 'left',
          component: CellImageAndText,
          target: (row) => {
            const auth = this.authenticationsList.find(auth => auth._id === row.id)
            let image = null
            try {
              image = auth ? this.providerIcons[auth.provider] : ''
            } catch (err) {
              image = null
            }
            return {
              text: this.$t(`profile.security.application.${auth?.provider}`),
              image: image
            }
          },
          paddingLeft: 17,
          grow: 0.8
        }, {
          name: 'email',
          label: this.$t('profile.security.email'),
          align: 'left',
          target: (row) => {
            return row.email || this.user.email
          },
          paddingLeft: 20
        }]
      }
    },
    recentSession () {
      return {
        data: this.listSession,
        actions: [{
          id: 'delete',
          if: (row) => {
            return !row.active
          },
          icon: 'fp4 fp4-trash-can',
          click: async (row) => {
            const confirmResult = await ConfirmModal(this, {
              contentHtml: true,
              content: `<span style="font-size:14px">${this.$t('treeview.remove.modal.head')}${this.$t('treeview.remove.modal.question_mark')}<br />${this.$t('treeview.remove.modal.tail')} </span><br>`,
              title: this.$t('profile.security.revoke'),
              yes: {
                text: this.$t('fpui-modal-confirm.remove'),
                color: 'red'
              }
            })
            if (!confirmResult) return
            try {
              await this.user.revokeSession(row._id)
              // Reload recent sessions data for updating table
              this.listSession = await this.user.listSession()
              this.$fpuiMessageBlock.success('Success')
            } catch (err) {
              this.$fpuiMessageBlock.error(err)
            }
          }
        }],
        columns: [{
          name: 'device',
          label: this.$t('profile.security.device'),
          align: 'left',
          target: row => {
            const parser = new UAParser()
            parser.setUA(row.ua)
            const result = parser.getResult()
            return `${result.os.name} - ${result.browser.name}`
          },
          paddingLeft: 20,
          grow: 0.8
        }, {
          name: 'first_connection',
          label: this.$t('profile.security.first_connection'),
          align: 'left',
          target: (row) => {
            return moment(row.created_at).format(this.$t('date.formatter.short'))
          },
          sortable: row => row.created_at,
          paddingLeft: 20
        }, {
          name: 'last_connection',
          label: this.$t('profile.security.last_connection'),
          align: 'left',
          target: (row) => {
            return moment(row.updated_at).format(this.$t('date.formatter.short'))
          },
          sortable: row => row.updated_at,
          reverse: true,
          paddingLeft: 20
        }]
      }
    }
  },
  async mounted () {
    // Load recent sessions data
    this.listSession = await this.user.listSession()
    // Load this to get password rules
    this.preferences = await this.$api.FPAPI.preferences('hq', null, { noOrganizationId: true })
  },
  async created () {
    // Get all provider id and name for linked account
    this.authenticationsList = await this.$api.KING.authentications.list({ noOrganizationId: true })
    // Add message listener for getting the result of window.open
    window.addEventListener('message', this.eventListener, false)
  },
  destroyed () {
    // On destroy, remove message event listener
    window.removeEventListener('message', this.eventListener)
  },
  methods: {
    icon (ruleValue) {
      return !ruleValue ? 'fp4-check-bold' : 'fp4-xmark-bold'
    },
    async resetPassword () {
      try {
        if (!this.hasPasswordError) {
          await this.user.resetPassword({
            oldpassword: this.password.oldpassword,
            password: this.password.password
          })
          this.$fpuiMessageBlock.success(this.$t('profile.security.reset_password_success'))
          setTimeout(async () => {
            await this.$store.api.KING.logout()
            document.location.href = window.location.origin
          }, 3000)
        }
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
      }
    },
    forgetPassword () {
      // TODO Open forget pwd link
    },
    async linkAccount (provider) {
      const config = await Config()
      const queryString = qs.stringify({
        authentication_provider: provider._id,
        app_id: config.APP_ID,
        redirect_uri: window.location.origin + '/#/iframe-callback',
        token_mode: 'cookie'
      })
      const url = `${config.KING}/v4/login?${queryString}`
      window.open(url, '', 'menubar=no, status=no, scrollbars=no, menubar=no, width=600, height=500')
    },
    eventListener (event) {
      if (event?.data?.id === 'auth' && event?.data?.op === 'ADD_LINKED_ACCOUNT') {
        this.$store.dispatch('CHECK_CMP_SESSION')
      }
    }
  }
}
</script>
<style lang="less">
@import "~@/shared/styles/_variables.less";

.security {
  display: flex;
  flex-direction: column;
  margin: 24px auto;
  width: 556px;
  gap: 20px;
  .section {
    padding: 20px 0 12px 0;
  }
  .title {
    font-size: 12px;
    color: @blue;
    font-weight: 600;
    text-transform: uppercase;
    &.title-margin {
      margin: 0 20px 10px 20px;
    }
  }
  .text {
    font-size: 14px;
  }
  .line {
    margin-bottom: 15px;
  }
  .password-reset-container {
    padding: 0 20px;
    .error-message {
      color: @red;
    }
    .old-password {
      .link {
        position: relative;
        top: -55px;
        left: 435px;
        color: @grey;
        cursor: pointer;
        text-decoration: underline;
        font-weight: 600;
      }
    }
    .button-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
    }
    .line {
      .fpui-input-password-strength {
        .fpui-input-label {
          .fpui-helper {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  .linked-account {
    margin-top: 52px;
    .link-account-container {
      margin-top: 29px;
      margin-left: 17px;
      display: flex;
      align-items: center;
      height: 30px;
      color: @grey;
      cursor: pointer;
      font-size: 11px;
      font-weight: 600;
      letter-spacing: 0;
      .provider {
        img {
          width: 30px;
          margin-right: 10px;
        }
      }
      .link-text {
        margin-left: 15px;
      }
    }
  }
  .security-questions {
    margin-top: 59px;
    display: flex;
    justify-content: space-between;
    .fpui-input-toggle {
      width: auto;
    }
  }
}
</style>

