<template lang="pug">
.organization-members
  fp-title(:parent="$t('organization.users')")
  img.placeholder(:src="require('@/core/assets/img/members-placeholder.jpg')")
  .iam-redirect
    i.fp4.fp4-users
    .title {{ $t('organization.users.redirect.title') }}
    .description {{ $t('organization.users.redirect.description') }}
    .cta
      fpui-button(
        color='blue-flash'
        @click="redirectToIam()"
      ) {{ $t('organization.users.redirect.cta') }}
    .helper-iam(@click='learnMore')
      fpui-helper(text='')
      fpui-input-label {{ $t('organization.users.redirect.learn_more') }}
</template>

<script>

export default {
  props: {
    organizationId: {
      type: String,
      required: true
    }
  },
  methods: {
    redirectToIam () {
      window.open(`/iam/#/${this.$route.params.organizationId}/users`, '_self')
    },
    learnMore () {
      window.open('https://docs.forepaas.io/#/en/product/organisations/orga_settings?id=members', '_blank')
    }
  }
}


</script>

<style lang="less">
  .organization-members {
    height: 100%;
    background-color: white;
    overflow: hidden;
    position: relative;
    > .placeholder {
      position: absolute;
      top: 87px;
      left: 0;
      width: 100%;
      z-index: 0;
      filter: blur(14px);
      padding: 20px;
    }
    .iam-redirect {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 70px 0;
      gap: 25px;
      > i {
        font-size: 70px;
        color: #0089C0;
      }
      > .title {
        font-weight: 400;
        font-size: 22px;
        text-align: center;
        max-width: 260px;
      }
      > .description {
        font-size: 14px;
        text-align: center;
        max-width: 430px;
      }
      > .cta {
        text-align: center;
      }
      .helper-iam {
        display: flex;
        .fpui-helper {
          padding-right: 5px;
        }
      }
    }
  }
</style>
