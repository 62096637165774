<template lang="pug">
.home-organization-container
  .dataplants
    fp-loader(v-if="loading || $store.getters.NEED_ORGANIZATION_LOGIN")
    .dataplants-container(v-else)
      waiting-for-approval(
        v-if="requestToJoinPending"
        :organization-id="organizationId"
      )

      .greetings-widgets-dataplants-container(
        v-if="dataplants.length"
      )
        .greetings-container
          .left
            .greetings {{ $t(greetingsMessage, [currentUser ? currentUser.firstname : '']) }}
            .description(
              v-html="$sanitize($t('home_orga.working_on_organization', [organizationName, organizationSettingsLink]))"
            )
          .right
            fpui-input-select.manage-widgets(
              :options="widgetsOptions"
              @input='(v) => manageWidget(v)'
              :searchable="false"
            )
              template(v-slot:content="slotProps")
                fpui-button(
                  color="grey"
                  @click="slotProps.open"
                  icon="fp4 fp4-plus-grid-2"
                  icon-left
                  autoWidth
                  reverse
                  unbordered
                  dropdownIcon
                  noshadow
                ) {{ $t('home_orga.manage_widgets') }}

        .widgets-container(
          v-if="displayWidgets"
        )
          .section-title {{ $t('home_orga.widgets_title') }}
          widgets(
            ref="widgets-layout"
            v-if="widgetsList.length"
            :widgets="widgetsList"
            :organization-id="organizationId"
          )
          .no-widget(
            v-if="!widgetsList.length"
          )
            .title {{ $t('home_orga.no_widget.title') }}
            .description(v-html="$t('home_orga.no_widget.description')")
            //- If var is put in v-html -> use $sanitize()

            .button
              fpui-button(
                color="white"
                autoWidth
                icon="fp4 fp4-plus"
                icon-left
                @click="manageWidget('add-widget')"
              ) {{ $t('home_orga.add_widgets') }}

        .back-to-recent-container(
          v-if="backToRecentList.length"
        )
          .title-all-services-container
            .section-title(
              v-html="$t('home_orga.back_to_recent_work_title')"
            )
            //- If var is put in v-html -> use $sanitize()

            a.all-services(
              :href="`/#/home/${organizationId}/services`"
            )
              span {{ $t('home_orga.see_all_services') }}
              i.fp4.fp4-angle-right
          .recent-list
            a.recent(
              v-for="recent in backToRecentList"
              :href="getLinkProject(recent)"
              @click.stop="trackOpenService"
            )
              .service-project-container
                .service {{ $t(`home_orga.${recent.type}`) }}
                .project {{ projectName(recent) }}
              img(
                :class="recent.type"
                :src="serviceIcon(recent.type)"
              )

        .dataplants-list-container
          .section-title {{ $t('home_orga.your_projects_title') }}
          .dataplants-list
            dataplant-block-new(
              v-if="subscriptionStatus === 'active' || subscriptionStatus === 'non_renewing' || subscriptionStatus === 'future' || subscriptionStatus === 'in_trial'"
              :organizationId="organizationId"
            )
            dataplant-block(
              v-for="dataplant in dataplants"
              :key="dataplant.id"
              :subscription="subscription"
              :project-id="dataplant.id"
              :organization="organization"
            )
            dataplant-block-subscription-paused-cancelled(
              v-if="subscriptionStatus === 'paused' || subscriptionStatus === 'cancelled'"
              :organization-id="organizationId"
            )
            dataplant-block-subscription-non-renewing(
              v-if="subscriptionStatus === 'non_renewing'"
              :organization-id="organizationId"
            )
        user-actions(
          :organization-id="organizationId"
        )

      onboarding(
        v-if="!dataplants.length && !requestToJoinPending && !loading"
        :organization-id="organizationId"
      )

</template>

<script>
import moment from 'moment'

import DataplantBlock from '@/core/components/Dataplant/HomeBlock/Dataplant'
import DataplantBlockNew from '@/core/components/Dataplant/HomeBlock/NewDataplant'
import DataplantBlockSubscriptionPausedCancelled from '@/core/components/Dataplant/HomeBlock/SubscriptionPausedCancelled'
import DataplantBlockSubscriptionNonRenewing from '@/core/components/Dataplant/HomeBlock/SubscriptionNonRenewing'
import Onboarding from '@/core/components/Home/Onboarding/Onboarding.vue'
import UserActions from '@/core/components/Home/UserActions.vue'
import Config from '@/shared/Config'
import Store from '@/shared/components/store'

import WaitingForApproval from './WaitingForApproval.vue'
import Widgets from './Widgets/Widgets.vue'
import widgetsListStore from './Widgets/widgets'

export default {
  components: {
    DataplantBlock,
    DataplantBlockNew,
    DataplantBlockSubscriptionNonRenewing,
    DataplantBlockSubscriptionPausedCancelled,
    Onboarding,
    UserActions,
    WaitingForApproval,
    Widgets
  },
  props: {
    organizationId: { type: String, required: true }
  },
  data () {
    return {
      loading: false,
      config: null
    }
  },
  computed: {
    organization () {
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    },
    organizationName () {
      return this.organization?.name || this.config?.ORGANIZATION_NAME || ''
    },
    organizationSettingsLink () {
      return `${this.config?.FPUI}/#/organization/${this.organizationId}`
    },
    subscription () {
      return this.organization?.subscription
    },
    subscriptionStatus () {
      if (this.organization.bypass_billing) return 'active'
      if (!this.subscription) return null
      return this.subscription?.status
    },
    dataplants () {
      return this.$store.getters.DATAPLANTS_BY_ORGANIZATION_ID(this.organizationId)
    },
    currentUser () {
      return this.$store.getters.SESSION
    },
    widgets () {
      return this.currentUser?.preferences?.home_page_preferences?.[this.organizationId]?.widgets || {}
    },
    displayWidgets () {
      return this.widgets?.display === undefined ? true : this.widgets.display
    },
    widgetsList () {
      return this.widgets.list || []
    },
    userOrganizationsHasSubscriptionsNotInTrial () {
      return this.$store.getters.ALL_ORGANIZATIONS_SUBSCRIPTIONS.filter(sub => sub.status !== 'in_trial').length
    },
    requestToJoinPending () {
      return this.organization?.pending_request
    },
    widgetsOptions () {
      return [{
        label: this.$t('home_orga.add_widgets'),
        value: 'add-widget',
        disabled: this.widgetsList.length >= 8,
        tooltip: this.widgetsList.length >= 8 ? this.$t('home_orga.add_widgets.limit_reached') : ''
      }, {
        label: this.displayWidgets ? this.$t('home_orga.hide_widgets') : this.$t('home_orga.display_widgets'),
        value: this.displayWidgets ? 'hide-widget' : 'display-widget'
      }]
    },
    backToRecentList () {
      const dataplants = this.dataplants.map(d => d.id)
      // Filter with existing dataplants, so removed Dataplants will not display anymore
      const recentList = (this.currentUser?.preferences?.home_page_preferences?.[this.organizationId]?.recent_projects_home)?.filter(l => dataplants.includes(l.projectId)) || []

      if (recentList.length > 4) return recentList.slice(0, 4)
      return recentList
    },
    servicesLink () {
      return ''
    },
    greetingsMessage () {
      const hours = moment().hours()
      if (hours < 12) return 'home_orga.greetings.morning'
      if (hours >= 12 && hours < 18) return 'home_orga.greetings.afternoon'
      if (hours <= 23) return 'home_orga.greetings.evening'

      return 'home_orga.greetings.morning'
    },
    openSidebar () {
      return this.$store.getters.OPEN_SIDE_BAR
    }
  },
  watch: {
    async $route () {
      await this.loadDataplants()
    },
    dataplants (val, oldVal) {
      if (val?.length !== oldVal?.length) {
        // Temporary fix, backend should loop through users and remove recent projects and services
        // when affected projects and services are not available anymore
        const projectsId = val.map(dp => dp.id)
        const recentList = this.currentUser?.preferences?.home_page_preferences?.[this.organizationId]?.recent_projects_home || []
        const filteredRecentList = recentList.filter(l => projectsId.includes(l.projectId))

        if (recentList.length !== filteredRecentList.length) {
          this.currentUser.update(`home_page_preferences.${this.organizationId}.recent_projects_home`, filteredRecentList)
        }
      }
    }
  },
  async mounted () {
    this.config = await Config()

    if (!this.$store.getters.ORGANIZATIONS.length) await this.$store.dispatch('LOAD_ORGANIZATIONS')
    // Check if user can go to this organization
    if (!this.$store.getters.ORGANIZATION_BY_ID(this.config.ORGANIZATION_ID)) {
      this.$store.commit('REMOVE_ACTIVE_ORGANIZATION')
      window.open('/', '_self')
    } else {
      await this.loadDataplants()
    }
  },
  async destroyed () {
    clearTimeout(this.timemoutdp)
  },
  methods: {
    async loadDataplants () {
      if (this.$store.getters.NEED_ORGANIZATION_LOGIN) return
      this.timemoutdp = null
      this.loading = true
      await this.watchDataplant()
      if (!this.organization) return this.$router.push('/home')
      await this.organization.loadSubscription()
      this.loading = false

      const trackFrom = window.localStorage.getItem('tracking-from')
      if (trackFrom) {
        if (trackFrom.includes('{')) {
          const trackObj = JSON.parse(trackFrom)
          this.$analytics.track('Open organization', { from: trackObj.from })
          if (trackObj.from === 'header') {
            this.$analytics.track('Switch organization from header', {
              organization_id: trackObj.fromOrganizationId,
              destination_organization_id: this.organizationId
            })
          }
        } else {
          this.$analytics.track('Open organization', { from: trackFrom })
        }
        window.localStorage.removeItem('tracking-from')
      }
    },
    async watchDataplant () {
      clearTimeout(this.timemoutdp)
      try {
        await this.$store.dispatch('REFRESH_DATAPLANT_BY_ORGANIZATION_ID', {
          organizationId: this.$route.params.organizationId,
          ignoreCache: true
        })
      } catch (err) {
        console.error(err)
      }
      this.timemoutdp = setTimeout(() => {
        this.watchDataplant()
      }, 30000)
    },
    manageWidget (value) {
      if (value === 'add-widget' && this.widgetsList.length < 8) {
        for (const [, value] of Object.entries(widgetsListStore)) {
          if (this.widgetsList.find(w => w.i === value.id)) value.disable = true
          else value.disable = false
        }

        // We do this to be able to use them in onSelect
        const widgetsList = this.widgetsList
        const currentUser = this.currentUser
        const organizationId = this.organizationId
        const refs = this.$refs

        this.$modal.show(Store, {
          name: this.$t('home_orga.widgets'),
          items: widgetsListStore,
          onSelect (widget) {
            const widgetTile = {
              i: widget.id,
              x: 0,
              y: 0,
              width: 3,
              height: 230,
              title: widget.name
            }

            currentUser.update(`preferences.home_page_preferences.${organizationId}.widgets.list`, [...widgetsList, widgetTile])
            if (refs?.['widgets-layout']) refs['widgets-layout'].addTile(widgetTile, 'add-column')
            this.$analytics.track('Add widget on organziation home', { widget: widget.id })
            this.$emit('close')
          }
        }, {
          width: 980,
          height: 640
        })
      }
      if (value === 'hide-widget') {
        this.$analytics.track('Hide widget section on organization home')
        this.currentUser.update(`preferences.home_page_preferences.${this.organizationId}.widgets.display`, false)
      }
      if (value === 'display-widget') {
        this.currentUser.update(`preferences.home_page_preferences.${this.organizationId}.widgets.display`, true)
      }
    },
    getLinkProject (recent) {
      const baseUrl = this.config?.FPUI
      return `${baseUrl}/${recent.type === 'gab' ? 'aab' : recent.type}/#/${recent.projectId}`
    },
    projectName (recent) {
      const project = this.dataplants.find(d => d.id === recent.projectId)
      return project?.name
    },
    trackOpenService () {
      window.localStorage.setItem('tracking-from', 'organization home')
    },
    serviceIcon (type) {
      try {
        return require(`@/core/components/Dataplant/assets/${type}_blue_flash.svg`)
      } catch {
        return null
      }
    }
  }
}
</script>

<style lang="less">
.home-organization-container {
  height: 100%;
  .dataplants {
    overflow: auto;
    width: 100%;

    .greetings-widgets-dataplants-container {
      max-width: 1164px;
      margin: auto;
      padding: 20px 28px 30px 28px;

      .section-title {
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #0089C0;
        text-transform: uppercase;
        text-align: left;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        i {
          font-size: 20px;
          margin-left: 5px;
        }
      }

      .greetings-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 66px;
        padding: 8px 0;
        margin-bottom: 20px;
        .left {
          text-align: left;
          .greetings {
            font-weight: 600;
            font-size: 22px;
            line-height: 28px;
            color: #3E4550;
            margin-bottom: 4px;
          }
          .description {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #748294;

            .link-organization-settings {
              font-size: 14px;
              color: #0089C0;
              text-decoration: underline;
            }
          }
        }
        .right {
          .manage-widgets {
            .fp4-chevron-down {
              font-size: 12px;
            }
            .dropdown {
              top: 40px;
              left: unset;
              min-width: unset;
              li {
                height: 44px;
                padding: 12px 20px;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: -0.01em;
                color: #3E4550;
                cursor: pointer;
                border-bottom: none;
                &:hover {
                  color: #0089C0;
                  background: none;
                  box-shadow: none;
                }
              }
            }
          }
        }
      }

      .widgets-container {
        .no-widget {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 16px 0px;
          width: 100%;
          height: 150px;
          border: 1px dashed #CBD3DB;
          border-radius: 5px;

          .title {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #3E4550;
          }
          .description {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #748294;
            margin-bottom: 16px;
            width: 410px;
          }
        }
      }

      .back-to-recent-container {
        margin-top: 10px;

        .title-all-services-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;

          .section-title {
            margin-bottom: 0;

            i {
              font-size: 16px;
            }
          }

          .all-services {
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            display: flex;
            align-items: center;
            letter-spacing: -0.01em;
            color: #00CCF9;
            cursor: pointer;
            &:hover {
              span {
                text-decoration: underline;
              }
            }

            .fp4-angle-right {
              font-size: 16px;
            }
          }
        }

        .recent-list {
          display: grid;
          gap: 16px;
          grid-template-columns: repeat(auto-fill, 265px);

          .recent {
            padding: 10px 16px;
            width: 264px;
            height: 58px;
            background: #FFFFFF;
            border: 1px solid #E4E7EC;
            box-shadow: 0px 2px 4px rgba(52, 65, 78, 0.05);
            border-radius: 5px;
            text-align: left;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .service-project-container {
              .service {
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
                color: #3E4550;
              }
              .project {
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                text-decoration-line: underline;
                color: #0089C0;
                text-overflow: ellipsis;
                overflow:hidden;
                white-space: nowrap;
                width: 190px;
              }
            }
            img {
              height: 40px;
              right: 10px;
              top: 10px;
            }

            &:hover {
              background-color: #eff8fb;
              border: 1px solid #00ccf9;
            }
          }
        }
        .no-recent {
          text-align: left;
        }
      }

      .dataplants-list-container {
        margin-top: 30px;
        .dataplants-list {
          display: grid;
          gap: 16px;
          grid-template-columns: repeat(auto-fill, 265px);
        }
      }
    }

    // Change the max-widths depending on breaking point sizes, min-width because
    // It has to start at that point and max-width zould make it for all sizes
    // Under so it zould only apply the first one
    @media screen and (min-width: 1560px) {
      .greetings-widgets-dataplants-container {
        max-width: 1560px;
      }
    }

    @media screen and (min-width: 1850px) {
      .greetings-widgets-dataplants-container {
        max-width: 1850px;
      }
    }

    @media screen and (min-width: 2150px) {
      .greetings-widgets-dataplants-container {
        max-width: 2150px;
      }
    }

    @media screen and (min-width: 2440px) {
      .greetings-widgets-dataplants-container {
        max-width: 2440px;
      }
    }
  }
}
</style>
