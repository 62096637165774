<template lang="pug">
.home
  organization-selector-header
  organization-selector(v-if='organizations.length')
</template>

<script>
import OrganizationSelectorHeader from '@/core/components/Home/OrganizationSelectorHeader'
import OrganizationSelector from '@/core/components/Home/OrganizationSelector'

export default {
  components: { OrganizationSelector, OrganizationSelectorHeader },
  computed: {
    organizations () {
      return this.$store.getters.ORGANIZATIONS
    },
    currentUser () {
      return this.$store.getters.SESSION
    }
  },
  watch: {
    organizations () {
      this.newOrganizationIfNone()
    }
  },
  mounted () {
    this.newOrganizationIfNone()
  },
  methods: {
    newOrganizationIfNone () {
      // TODO: if one organization, if has enforce SSO, redirect to SSO login
      if (!this.organizations?.length) this.$router.push('/home/new')
    }
  }
}
</script>

<style lang="less">

</style>
