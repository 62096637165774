<template lang="pug">
.sms-auth-and-backup-code-settings
  fp-loader(v-if="loading")
  .message
    i.fp4.fp4-circle-check-small
    .text {{ $t('profile.security.mfa.modal.sms_auth_and_backup.message') }}
  .enable-sms-auth.border-bottom
    .section
      .title {{ $t('profile.security.mfa.modal.sms_auth_and_backup.sms.title') }}
      .text {{ $t('profile.security.mfa.modal.sms_auth_and_backup.sms.content') }}
      .phone-number-section
        .phone-number-wrapper
          fpui-input-phone(
            :label="$t('profile.security.mfa.modal.sms_auth_and_backup.sms.phone_number_label')"
            :value="user.phone"
            :preferedCountries="['fr', 'us']"
            :disabled="!isEditingPhoneNumber"
            @input="(value)=>user.update('phone', value, false)"
            @hasError="val => hasError = val"
          )
          .button
            fpui-button(
              v-if="!isEditingPhoneNumber"
              color="white"
              auto-width
              @click="isEditingPhoneNumber = true"
            ) {{ $t('profile.security.mfa.modal.sms_auth_and_backup.sms.button.change_phone_number') }}
            fpui-button(
              v-if="isEditingPhoneNumber"
              color="blue-flash"
              auto-width
              :disabled="!canChangePhoneNumber"
              @click="changePhoneNumber()"
            ) {{ $t('button.save') }}
      .button.enable-sms-auth
        fpui-button(
          color="blue-flash"
          auto-width
          :disabled="isSmsAuthEnabled || !user.phone || isEditingPhoneNumber"
          @click="enableSmsAuth()"
        ) {{  isSmsAuthEnabled ? $t('profile.security.mfa.modal.sms_auth_and_backup.sms.button.already_enabled') : $t('profile.security.mfa.modal.sms_auth_and_backup.sms.button.enable_sms_auth') }}

  .download-backup-codes.section
    .title {{ $t('profile.security.mfa.modal.sms_auth_and_backup.backup.title') }}
    .text {{ $t('profile.security.mfa.modal.sms_auth_and_backup.backup.content') }}
    .button.download-button
      fpui-button(
        color="blue-flash"
        icon='fp4 fp4-down-to-line'
        icon-left
        auto-width
        :disabled="isBackupCodeDownloaded"
        @click="downloadBackupCodes()"
      ) {{ $t('profile.security.mfa.modal.sms_auth_and_backup.backup.button') }}
    .last-downloaded-date(:style="{ visibility : !isBackupCodeDownloaded ? 'hidden' : ''}") {{ $t('profile.security.mfa.modal.sms_auth_and_backup.backup.last_downloaded', [ lastDownloadedDate ]) }}

</template>

<script>
import Date from '@/shared/filters/Date'

export default {
  props: {
    onClickEnableSmsAuthButton: { type: Function, default: () => {} }
  },
  data () {
    return {
      loading: false,
      isEditingPhoneNumber: false,
      hasError: false
    }
  },
  computed: {
    user () {
      return this.$store.getters.SESSION
    },
    isSmsAuthEnabled () {
      return this.user.mfa?.sms?.active
    },
    lastDownloadedDate () {
      return Date(this.user.mfa?.backup?.generated_at).format(this.$t('date.formatter.second.slash')) || ''
    },
    isBackupCodeDownloaded () {
      return !!this.user.mfa?.backup?.generated_at?.length
    },
    canChangePhoneNumber () {
      if (!this.hasError) return true
      return false
    }
  },
  methods: {
    enableSmsAuth () {
      this.$emit('close')
      this.onClickEnableSmsAuthButton()
    },
    async changePhoneNumber () {
      this.loading = true
      this.isEditingPhoneNumber = false
      await this.user.save()
      this.loading = false
      this.$fpuiMessageBlock.success(this.$t('profile.security.mfa.modal.sms_auth_and_backup.phone_number.success'))
    },
    async downloadBackupCodes () {
      this.loading = true
      try {
        const backupCodeList = await this.$api.KING.getMFABackupCode()
        await this.$store.dispatch('REFRESH_CURRENT_USER')
        const newBackupCodeList = []
        backupCodeList.forEach((code, idx) => {
          const target = code.match(/.{4}/g) // Separate every 4 letters
          const newCode = target.join(' ')
          newBackupCodeList.push(backupCodeList.length !== idx ? newCode + ',\n' : newCode)
        })
        const blob = new Blob(newBackupCodeList, { type: 'text/plain' })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `${this.user.name}-mfa-backup` // file name
        a.click()
        a.remove()
        this.$fpuiMessageBlock.success(this.$t('profile.security.mfa.modal.sms_auth_and_backup.backup.success'))
      } catch (err) {
        this.$fpuiMessageBlock.pop('error', err.message)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="less">
.two-factor-authentication-modal {
  .message {
    margin: 0 30px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    background: #9EDF10;
    display: flex;
    height: 24px;
    padding: 0px 10px;
    gap: 4px;
    border-radius: 5px;
    .fp4-circle-check-small {
      color: #FFF;
      font-size:16px;
    }
    .text {
      color: #FFF;
      font-size: 12px;
      letter-spacing: -0.12px;
    }
  }
  .section {
    .text {
      margin: 8px 0;
      color:  #748294;
      font-size: 12px;
      letter-spacing: -0.12px;
    }
    .phone-number-label {
      color: #97A7B7;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: -0.12px;
    }
    .phone-number-section{
      margin-bottom: 18px;
      .phone-number-wrapper {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        gap: 16px;
        .phone-number {
          input {
            width: 370px;
          }
        }
      }
    }
    .download-button {
      padding-top: 8px;
    }
  }
  .last-downloaded-date {
    margin-top: 8px;
    color: #97A7B7;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.12px;
  }
  .modal-footer-container{
    .footer-actions {
      justify-content: flex-end !important;
    }
  }
}
</style>
