<template lang="pug">
.setting-infrastructure
  .infrastructure.fp-container
    .fp-column
      .fp-row.settings-domains
      .fp-box.with-padding
        .box-title {{ $t('organization.settings.infrastructure.domains') }}
        .box-line
          .domains
            fpui-table.fp-no-margin(
              :columns='domains.columns'
              :actions='domains.actions'
              :data='domainsList'
            )
            fpui-button.add-domain(
              v-if="isAdmin"
              color='white',
              icon="fp4 fp4-plus",
              icon-left,
              auto-width
              @click="domainModal()"
            ) {{ $t('organization.settings.infrastructure.add_domain') }}
      //- .fp-row.settings-client-application
      //- .fp-box.with-padding
      //-   .box-title {{ $t('organization.settings.infrastructure.client_application') }}
      //-   .box-line
      //-     .application
      //-       fpui-table.fp-no-margin(
      //-         :columns=[]
      //-         :actions=[]
      //-       )
      //-       fpui-button.add-application(
      //-         color='white',
      //-         icon="fp4 fp4-plus",
      //-         icon-left,
      //-         auto-width
      //-         @click="applicationModal()"
      //-       ) {{ $t('organization.settings.infrastructure.new_application') }}

    .fp-column
      .fp-row.settings-certificates
      .fp-box.with-padding
        .box-title {{ $t('organization.settings.infrastructure.certificates') }}
        .box-line
          .certificates
            fpui-table.fp-no-margin(
              :columns='certificates.columns'
              :actions='certificates.actions'
              :data='certificatesList'
            )
            fpui-button.add-certificate(
              v-if="isAdmin"
              color='white',
              icon="fp4 fp4-plus",
              icon-left,
              auto-width
              @click="certificateModal()"
            ) {{ $t('organization.settings.infrastructure.add_certificates') }}
</template>



<script>
import DomainModal from './DomainModal'
import ConfirmModal from '@/shared/components/modals/Confirm'
import CertificateModal from './CertificateModal'
// import ApplicationModal from './ApplicationModal'
import Certificate from '@/shared/plugins/Api/FPAPI/Certificates'

export default {
  props: {
    organization: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      domains: {
        actions: [
          {
            id: 'remove',
            icon: 'fp4 fp4-trash-can',
            if: () => this.isAdmin,
            click: (row, index) => this.removeDomain(row)
          }
        ],
        columns: [
          {
            name: 'name',
            label: 'name',
            target: row => row.domain,
            sortable: true
          }
        ]
      },
      certificates: {
        actions: [
          {
            id: 'edit',
            icon: 'fp4 fp4-pencil',
            if: () => this.isAdmin,
            click: (row) => this.certificateModal(row)
          },
          {
            id: 'remove',
            icon: 'fp4 fp4-trash-can',
            if: () => this.isAdmin,
            click: (row) => this.removeCertificate(row)
          }
        ],
        columns: [
          {
            name: 'name',
            label: 'name',
            target: row => row.name,
            sortable: true
          },
          {
            name: 'domain',
            label: 'domain',
            target: row => row.domain,
            sortable: true
          }

        ]
      }
    }
  },
  computed: {
    domainsList () {
      return this.organization?.authorized_domain
    },
    certificatesList () {
      return this.organization?.certificates
    },
    organizationSession () {
      return this.$store.getters.ORGANIZATION_SESSION
    },
    isAdmin () {
      return this.organizationSession?.acls === '*'
    }
  },
  methods: {
    domainModal () {
      return new Promise((resolve) => {
        this.$modal.show(DomainModal, {
          onConfirm: async (domain) => {
            try {
              await this.organization.createDomain(domain)
              this.$store.commit('UPDATE_ORGANIZATION', this.organization)
              this.$fpuiMessageBlock.success(this.$t('organization.settings.infrastructure.add_domain.success'))
            } catch (err) {
              this.$fpuiMessageBlock.error(err)
            }
          }
        }, {
          height: 'auto',
          width: 550
        })
      })
    },
    async removeDomain (domain) {
      const confirmResult = await ConfirmModal(this, {
        contentHtml: true,
        content: `<span style="font-size:14px">${this.$t('treeview.remove.modal.head')} <span style="font-weight:600"> ${domain.domain}</span>${this.$t('treeview.remove.modal.question_mark')}<br />${this.$t('treeview.remove.modal.tail')} </span><br>`,
        title: this.$t('organization.settings.infrastructure.domains.remove_domain'),
        yes: {
          text: this.$t('fpui-modal-confirm.remove'),
          color: 'red'
        }
      },
      {
        height: 'auto',
        width: 550
      })
      if (!confirmResult) return
      await this.organization.removeDomain(domain.domain)
      this.$store.commit('UPDATE_ORGANIZATION', this.organization)
      this.$fpuiMessageBlock.success(this.$t('organization.settings.infrastructure.remove_domain.success'))
    },
    certificateModal (certificate = null) {
      const item = !certificate ? new Certificate({ organizationId: this.organization._id }) : new Certificate(certificate)
      return new Promise((resolve) => {
        this.$modal.show(CertificateModal, {
          certificate: item,
          onConfirm: async (certificate) => {
            try {
              if (certificate._id) {
                await this.organization.editCertificate(certificate)
                const updatedCertificateIndex = this.certificatesList.findIndex(c => c._id === certificate._id)
                if (updatedCertificateIndex !== -1) {
                  this.$set(this.certificatesList, updatedCertificateIndex, certificate)
                }
                this.$fpuiMessageBlock.success(this.$t('organization.settings.infrastructure.update_certificate.success', [certificate.name]))
              } else {
                const cert = await certificate.create(this.organization._id)
                this.certificatesList.push(cert)
                this.$store.commit('UPDATE_ORGANIZATION', this.organization)
                this.$fpuiMessageBlock.success(this.$t('organization.settings.infrastructure.add_certificate.success'))
              }
            } catch (err) {
              this.$fpuiMessageBlock.error(err)
            } finally {
              await this.$store.dispatch('LOAD_ORGANIZATION', this.organization?._id)
            }
          }
        }, {
          height: 'auto',
          width: 547
        })
      })
    },
    async removeCertificate (certificate) {
      const confirmResult = await ConfirmModal(this, {
        contentHtml: true,
        content: `<span style="font-size:14px">${this.$t('treeview.remove.modal.head')} <span style="font-weight:600"> ${certificate.name}</span>${this.$t('treeview.remove.modal.question_mark')}<br />${this.$t('treeview.remove.modal.tail')} </span><br>`,
        title: this.$t('organization.settings.infrastructure.certificate.remove_certificate'),
        yes: {
          text: this.$t('fpui-modal-confirm.remove'),
          color: 'red'
        }
      },
      {
        height: 'auto',
        width: 550
      })
      if (!confirmResult) return
      const toDelete = new Certificate(certificate)
      await toDelete.remove(this.organization.id)
      const newCertList = this.certificatesList.filter(c => c.id !== toDelete.id)
      this.organization.update('certificates', newCertList)
      this.$store.commit('UPDATE_ORGANIZATION', this.organization)
      this.$fpuiMessageBlock.success(this.$t('organization.settings.infrastructure.remove_certificate.success'))
    }
  }
}
</script>

<style lang="less">
  .setting-infrastructure {
    .fp-box {
      &.with-padding {
        padding-bottom: 21px !important;
      }
    }
    .add-domain {
      margin-top: 15px;
    }
    .add-certificate {
      margin-top: 15px;
    }
  }
</style>
