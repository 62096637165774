<template lang="pug">
.two-factor-authentication-modal.fpui-modal
  fp-loader(v-if="loading")
  fpui-modal-header(
    :title="$t('profile.security.mfa.modal.enable_mfa.title')"
    closeTopButton
    @close='close'
  )
  fpui-modal-body.modal-body
    authenticator-setting(
      v-if="step === 0"
      @send-code="val => verificationCode = val"
      :qr-code-info="qrCodeInfo"
    )
    sms-auth-and-backup-code-settings(
      v-if="step === 1"
      :on-click-enable-sms-auth-button="onClickEnableSmsAuthButton"
      @close="close()"
    )
    input-sms-confirmation-code(
      v-if="step === 2"
      @send-code="val => verificationCode = val"
    )
  fpui-modal-footer(
    :buttons="buttons"
  )
</template>

<script>
import AuthenticatorSetting from './AuthenticatorSetting.vue'
import SmsAuthAndBackupCodeSettings from './SmsAuthAndBackupCodeSettings.vue'
import InputSmsConfirmationCode from './InputSmsConfirmationCode.vue'

export default {
  components: { AuthenticatorSetting, SmsAuthAndBackupCodeSettings, InputSmsConfirmationCode },
  props: {
    qrCodeInfo: { type: Object, default: () => {} },
    onClick: { type: Function, required: true },
    onClickGoBack: { type: Function || null, default: null },
    stepProps: { type: Number, default: 0 },
    onClickEnableSmsAuthButton: { type: Function, default: () => {} }
  },
  data () {
    return {
      loading: false,
      verificationCode: '',
      step: 0
    }
  },
  computed: {
    user () {
      return this.$store.getters.SESSION
    },
    downloadedBackupCodes () {
      return this.user.mfa?.backup?.generated_at
    },
    enabledSmsAuth () {
      return this.user.mfa?.sms?.active
    },
    buttons () {
      if (this.step === 0) {
        return [
          {
            label: this.$t('button.cancel'),
            type: 'close',
            handler: () => {
              this.$emit('close')
            }
          },
          {
            label: this.$t('authentication.mfa.authenticator.send'),
            color: 'blue-flash',
            disabled: !this.canContinue,
            handler: async () => {
              const isCodeValidated = await this.checkVerificationCode('authenticator')
              if (isCodeValidated) {
                this.onClick()
                this.$emit('close')
              }
            }
          }
        ]
      } else if (this.step === 1) {
        let buttonLabel
        if (!this.downloadedBackupCodes) buttonLabel = this.$t('profile.security.mfa.modal.sms_auth_and_backup.modal-footer.no_backup.button')
        else if (!this.enabledSmsAuth) buttonLabel = this.$t('profile.security.mfa.modal.sms_auth_and_backup.modal-footer.no_sms.button')
        return this.lastDownloadedDate || (this.downloadedBackupCodes && this.enabledSmsAuth)
          ? [
              {
                label: this.$t('button.close'),
                color: 'blue-flash',
                handler: async () => {
                  this.$emit('close')
                }
              }
            ]
          : [
              {
                label: this.$t(buttonLabel),
                color: 'white',
                buttonStyle: { width: `${!this.downloadedBackupCodes ? '185px' : '260px'} !important` },
                handler: async () => {
                  if (!(this.downloadedBackupCodes && this.enabledSmsAuth)) {
                    this.onClick()
                  }
                  this.$emit('close')
                }
              }
            ]
      } else {
        return this.onClickGoBack !== null
          ? [
              {
                label: this.$t('button.back'),
                color: 'blue-flash',
                handler: async () => {
                  this.onClickGoBack()
                  console.log('onClickGoBAck button')
                  this.$emit('close')
                }
              },
              {
                label: this.$t('profile.security.mfa.modal.enable_mfa.button'),
                color: 'blue-flash',
                disabled: !this.canContinue,
                handler: async () => {
                  if (await this.checkVerificationCode('sms')) {
                    this.onClick()
                    this.$emit('close')
                  }
                }
              }
            ]
          : [
              {
                label: this.$t('profile.security.mfa.modal.enable_mfa.button'),
                color: 'blue-flash',
                disabled: !this.canContinue,
                handler: async () => {
                  if (await this.checkVerificationCode('sms')) {
                    this.onClick()
                    this.$emit('close')
                  }
                }
              }
            ]
      }
    },
    canContinue () {
      if (this.loading) return false
      return this.verificationCode.length === 6
    }
  },
  created () {
    if (this.stepProps) this.step = this.stepProps
  },
  methods: {
    close () {
      this.$emit('close')
    },
    async checkVerificationCode (type) {
      try {
        this.loading = true
        await this.$store.dispatch('VALIDATE_KING_MFA_CODE', {
          type,
          otp: this.verificationCode
        })
        this.$store.dispatch('CHECK_CMP_SESSION')
        return true
      } catch (err) {
        this.$fpuiMessageBlock.pop('error', err.message)
        return false
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="less">
.two-factor-authentication-modal {
  .modal-body{
    padding: 0 !important;
    .title {
      color: @blue;
      font-family: "Source Sans Pro";
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.12px;
    }
    .border-bottom {
      border-bottom: 1px solid #E4E7EC;
    }
    .section {
      padding: 16px 30px;
    }
  }
  .footer-actions {
    margin: 0 11px;
    justify-content: space-between !important;
  }
}
</style>
