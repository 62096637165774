<template lang="pug">
.onboarding
  fp-loader(v-if="loading")
  .content
    section.paas-presentation
      .paas-presentation-content
        h1(v-html="$t('core.onboarding.paas_presentation.title')")
        //- If var is put in v-html -> use $sanitize()

        .presentation-description(v-html="$t('core.onboarding.paas_presentation.description')")
        //- If var is put in v-html -> use $sanitize()

        .cta-container
          h2 {{ $t('core.onboarding.paas_presentation.cta.title') }}
          .description(v-html="$t('core.onboarding.paas_presentation.cta.description')")
          //- If var is put in v-html -> use $sanitize()

          a(
            href="https://register.gotowebinar.com/rt/540462080911231065"
            target="_blank"
          )
            fpui-button(color="white") {{ $t('core.onboarding.paas_presentation.cta.button') }}
      .video-logo-animation-container
        img(:src="require('@/core/components/Home/assets/product-animation.svg')")
        //- This is animation svg and we keep this for now for the time to put it back
        //- img(:src="require('@/core/components/Home/assets/services_animation.svg')")
    section.welcome-flow
      h1 {{ $t('core.onboarding.welcome-flow.get-started') }}
      quick-start(
        :organization-id="organizationId"
        :can-create-project="canCreateProject"
      )
      data-project-creation(
        type='empty'
        :organization-id='organizationId'
        :can-create-project="canCreateProject"
      )
      data-project-creation(
        type='templates'
        :organization-id='organizationId'
        :can-create-project="canCreateProject"
        :from-onboarding="true"
      )
</template>

<script>
import QuickStart from '@/core/components/Home/Onboarding/QuickStart'
import DataProjectCreation from '@/core/components/Home/Onboarding/DataProjectCreation'

export default {
  components: {
    QuickStart,
    DataProjectCreation
  },
  props: {
    organizationId: { type: String, default: '' }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    organizations () {
      return this.$store.getters.ORGANIZATIONS
    },
    organization () {
      if (this.organizationId === '') return false
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    },
    organizationSession () {
      return this.$store.getters.ORGANIZATION_SESSION
    },
    canCreateProject () {
      return this.$acl(this.organizationId, this.organizationSession).can('core', 'dataplants', 'create')
    },
    currentUser () {
      return this.$store.getters.SESSION
    },
    dataplants () {
      if (!this.organization) return []
      return this.$store.getters.DATAPLANTS_BY_ORGANIZATION_ID(this.organizationId)
    },
    subscription () {
      return this.organization?.subscription || null
    },
    subscriptionStatus () {
      if (this.organization.bypass_billing) return 'active'
      if (!this.subscription) return null
      return this.subscription?.status
    }
  },
  async mounted () {
    if (this.organization) {
      this.loading = true
      await this.organization.loadSubscription()
      this.loading = false
    }
  },
  methods: {
  }
}
</script>

<style lang="less">
.onboarding {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  margin-top: 24px;
  margin-bottom: 24px;
  > .content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap-reverse;
    gap: 70px;
    section {
      &.paas-presentation {
        background: rgb(0,204,249);
        position: relative;
        background: linear-gradient(-19deg, #00CCF9 23%, #03569A 84%);
        &:before {
          position: absolute;
          top: 0;
          content: '';
          width: 100%;
          height: 100%;
          mask-image: linear-gradient(180deg, transparent 5%, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 1) 15%, transparent 95%);
          background-image: url("~@/core/components/Home/assets/vector-welcome.svg"), linear-gradient(90deg, rgba(255, 255, 255, 1), transparent 100%);
          opacity: 0.22;
        }
        border-radius: 22px;
        width: 650px;
        .video-logo-animation-container {
          padding: 30px 0;
        }
        .paas-presentation-content {
          padding: 10px 40px;
          h1 {
            color: @white;
            font-weight: bold;
            font-size: 35px;
            line-height: 44px;
            margin-top: 30px;
            margin-bottom: 37px;
          }
          .presentation-description {
            color: @white;
            font-size: 16px;
            line-height: 20px;
          }
          .cta-container {
            margin-top: 100px;
            background: rgba(255, 255, 255, 0.75);
            backdrop-filter: blur(35px);
            border-radius: 16px;
            padding: 24px;
            > h2 {
              font-size: 24px;
              font-weight: 600;
              line-height: 38px;
              margin: 0 0 12px 0;
            }
            > .description {
              font-weight: 400;
              font-size: 16px;
              line-height: 21px;
              color: @text-color;
              padding-bottom: 24px;
            }
          }
        }
      }
      &.welcome-flow {
        width: 630px;
        > h1 {
          font-weight: 600;
          font-size: 26px;
          line-height: 33px;
          color: @text-color;
          margin-top: 30px;
          margin-bottom: 41px;
        }
      }
    }
  }
}
</style>
