<template lang="pug">
.project-dashboard-tile(:class="cls")
  fp-loader(v-if="loading || blockLoading || isInstalling")

  .options-icon-container(
    v-if="editing"
  )
    span.size(
      v-if="!['api', 'app'].includes(service)"
      :class="{ active: isSmallSize, disabled: !canUpdateProject }"
      @click="resize('s')"
    ) S
    span.size(
      :class="{ active: isMediumSize, disabled: !canUpdateProject }"
      @click="resize('m')"
    ) M
    span.size(
      :class="{ active: isLargeSize, disabled: !canUpdateProject }"
      @click="resize('l')"
    ) L
    i.fp4.fp4-burger
  .options-icon-container(
    ref="service-options-button"
    v-if="!editing"
    @click="openOptions"
  )
    i.fp4.fp4-ellipsis
  .service-options(
    v-if="showOptions"
    ref="service-options"
  )
    .option(
      v-if="!(tile.width === 1 && tile.height === 1) && !['api', 'app'].includes(service)"
      :class="{ disabled: !canUpdateProject }"
      v-tooltip="{ content: !canUpdateProject ? $t('dashboard.options.can_not_update_project') : '', delay: 0 }"
      @click="resize('s')"
    )
      span {{ $t('dashboard.options.resize_s') }}
    .option(
      v-if="!(tile.width === 1 && tile.height === 2)"
      v-tooltip="{ content: !canUpdateProject ? $t('dashboard.options.can_not_update_project') : '', delay: 0 }"
      :class="{ disabled: !canUpdateProject }"
      @click="resize('m')"
    )
      span {{ $t('dashboard.options.resize_m') }}
    .option(
      v-if="!(tile.width === 2 && tile.height === 2)"
      :class="{ disabled: !canUpdateProject }"
      v-tooltip="{ content: !canUpdateProject ? $t('dashboard.options.can_not_update_project') : '', delay: 0 }"
      @click="resize('l')"
    )
      span {{ $t('dashboard.options.resize_l') }}
    .option(
      v-tooltip="{ content: !canUpdateProject ? $t('dashboard.options.can_not_update_project') : '', delay: 0 }"
      :class="{ disabled: !canUpdateProject }"
      @click="setEditingMode"
    )
      span {{ $t('dashboard.options.move_card') }}
    a.option.open(
      v-if="!['api', 'app'].includes(service)"
      :class="{ disabled: !hasAccess }"
      v-tooltip="{ content: !hasAccess ? $t('dashboard.options.can_not_update_project') : '', delay: 0 }"
      :href="link"
      @click.stop="sendTrack"
    )
      span {{ $t('dashboard.options.open') }}
    .option(
      v-if="!['api', 'app', 'control-center', 'gab', 'cdm'].includes(service)"
      :class="{ disabled: !canExportService }"
      v-tooltip="{ content: !canExportService ? $t('dashboard.options.can_not_update_project') : '', delay: 0 }"
      @click="exportService"
    )
      span {{ $t('dashboard.options.export') }}
    .option(
      v-if="!['api', 'app', 'control-center', 'gab', 'cdm'].includes(service)"
      :class="{ disabled: !canImportService }"
      v-tooltip="{ content: !canImportService ? $t('coming_soon') : '', delay: 0 }"
      @click="importService"
    )
      span {{ $t('dashboard.options.import') }}
      input(
        ref="importFile"
        :disabled="!canImportService"
        type="file"
        @change="importFile($event.target.files)"
      )
    //- .option.remove(
    //-   :class="{ disabled: !canUpdateProject || !canRemoveService, open: ['api', 'app'].includes(service) }"
    //-   v-tooltip="{ content: getRemoveTooltip, delay: 0 }"
    //-   @click="removeService"
    //- )
    //-   span {{ $t('dashboard.options.remove_service') }}

  .s-m-container
    component.s-container.pointer(
      :is="editing ? 'div' : 'a'"
      :class="[service]"
      :href="link"
      @click="sendTrack"
      @mouseover="setActiveTile(true)"
      @mouseleave="setActiveTile(false)"
    )
      .img-error-container
        .img-container
          img.icon(
            v-if="hasAccess"
            :src="icon"
          )
          img.icon(
            v-else
            :src="iconGrey"
          )
        .error-container(
          v-if="error || !hasAccess || isInstalling"
          :class="{ installing: isInstalling }"
          @click.prevent.stop="showErrorMessage"
        )
          .text {{ $t('dashboard.error.service_unavailable') }}
          .message(v-if="error") {{ error.message }}
          .message(v-if="!hasAccess") {{ $t('dashboard.access.tooltip') }}
          .message.installing(v-if="isInstalling") {{ $t('dashboard.service_installing.tooltip') }}
      .name-container(
        v-tooltip="{ content: !hasAccess ? $t('dashboard.access.tooltip') : '', delay: 0 }"
      )
        .name(
          :class="[service]"
          :id="service === 'app' ? 'button-project-app' : null"
        ) {{ $t(`dashboard.${service}.title`) }}
        i.fp4.fp4-lock-keyhole(
          v-if="!loading && !hasAccess && !error"
        )

    .m-container(
      v-if="isMediumSize || isLargeSize"
    )
      .buttons-container
        component.left(
          :is="`${service}-left-button`"
          :editing="editing"
          @click="clickService(service)"
        )
        component.right(
          v-if="!['api', 'app'].includes(service)"
          :is="`${service}-right-button`"
          :editing="editing"
        )

      .table-container(
        v-if="isMediumSize"
      )
        component(
          :is="`${service}-main-table`"
          @open-app="openApp"
          @edit-app="editApp"
        )

      .kpi-container(
        v-if="isLargeSize"
      )
        component(
          :is="`${service}-left-kpi`"
        )
        component(
          :is="`${service}-right-kpi`"
        )

  .l-container(
    v-if="isLargeSize"
  )
    .table-container(
      :style="{ height: ['api', 'app', 'iam', 'dpe', 'control-center'].includes(service) ? '290px' : '143px' }"
    )
      component(
        :is="service !== 'control-center' ? `${service}-main-table` : `${service}-second-table`"
        from-large
        @open-app="openApp"
        @edit-app="editApp"
      )
      component(
        v-if="!['api', 'app', 'iam', 'dpe'].includes(service)"
        :is="service !== 'control-center' ? `${service}-second-table` : `${service}-main-table`"
      )

</template>

<script>
import Config from '@/shared/Config'
import serviceComponents from './StatsTable'
import ImportModal from './Modals/ImportModal.vue'
import DeleteService from './Modals/DeleteService.vue'
import ErrorModal from './Modals/ErrorModal.vue'

export default {
  components: {
    ...serviceComponents
  },
  props: {
    error: { type: Error, default: null },
    hasAccess: { type: Boolean, required: true },
    isNewFeature: { type: Boolean, default: false },
    beta: { type: String, default: '' },
    betaText: { type: String, default: '' },
    loading: { type: Boolean, required: true },
    editing: { type: Boolean, required: true },
    service: { type: String, required: true },
    projectId: { type: String, required: true },
    mode: { type: String, default: 'bulk' },
    tile: { type: Object, required: true },
    serviceDependencies: { type: Object, required: true },
    serviceRequired: { type: Array, required: true }
  },
  data () {
    return {
      blockLoading: false,
      newFeatureIcon: require('../NEW_STATIC_CORNER.svg'),
      showOptions: false,
      config: null,
      healthCheckInterval: null,
      activeTile: false
    }
  },
  computed: {
    icon () {
      let icon = ''
      try {
        icon = require(`../assets/${this.service}.svg`)
      } catch (err) {
        icon = require('@/shared/assets/img/ovhcloud_logo_simplified_blue.png')
      }
      return icon
    },
    iconGrey () {
      let icon = ''
      try {
        icon = require(`../assets/${this.service}_grey.svg`)
      } catch (err) {
        icon = require('@/shared/assets/img/ovhcloud_logo_simplified_blue.png')
      }
      return icon
    },
    organizationSession () {
      return this.$store.getters.ORGANIZATION_SESSION
    },
    canUpdateProject () {
      if (!this.config) return true
      return this.$acl(this.config?.ORGANIZATION_ID, this.organizationSession).can('core', 'dataplants', 'update', { dataplantId: this.projectId })
    },
    isDependencyOf () {
      const services = []
      Object.keys(this.serviceDependencies).forEach(s => {
        if (this.serviceDependencies[s].includes(this.service)) services.push(s)
      })

      return services
    },
    isRequired () {
      return this.serviceRequired.includes(this.service)
    },
    canRemoveService () {
      return !this.isDependencyOf.length && !this.isRequired
    },
    getRemoveTooltip () {
      if (!this.canUpdateProject) return this.$t('dashboard.options.can_not_update_project')
      if (!this.canRemoveService) return this.$t('dashboard.options.can_not_remove_because_dependency_of', [this.isDependencyOf.join(', ')])
      return ''
    },
    cls () {
      const cls = []
      if (!this.hasAccess || this.isInstalling) cls.push('locked')
      if (this.loading || this.isInstalling) cls.push('loading')
      if (this.size) cls.push(`size-${this.size.toLowerCase()}`)
      if (this.editing) cls.push('editing')
      if (this.activeTile) cls.push('active')
      return cls.join(' ')
    },
    project () {
      return this.$store.getters.DATAPLANT_BY_ID(this.projectId)
    },
    link () {
      if (!this.hasAccess || this.editing) return 'javascript:void(0)'
      if (['cc', 'control-center'].includes(this.service)) {
        return `/cc/#/${this.project.organization_id}/${this.projectId}`
      }
      if (['bulk', 'iframe', 'internal'].includes(this.mode)) {
        let service = this.service
        if (this.service === 'mlm') service = 'ml'
        if (this.service === 'gab') service = 'aab'
        if (this.mode === 'bulk') return `/${service}/#/${this.projectId}`
        return `#/${this.service}/${this.projectId}`
      } else if (this.mode === 'redirect') {
        return `https://${this.project?.url}/${this.service}/hq/`
      }
      return 'javascript:void(0)'
    },
    currentUser () {
      return this.$store.getters.SESSION
    },
    size () {
      if (this.tile.width === 2 && this.tile.height === 2) return 'L'
      if (this.tile.width === 1 && this.tile.height === 2) return 'M'
      return 'S'
    },
    isSmallSize () {
      return this.size === 'S'
    },
    isMediumSize () {
      return this.size === 'M'
    },
    isLargeSize () {
      return this.size === 'L'
    },
    leftButton () {
      if (this.isSmallSize) return {}
      return {
        props: {

        },
        text: ''
      }
    },
    rightButton () {
      if (this.isSmallSize) return {}
      return {
        props: {

        },
        text: ''
      }
    },
    isInstalling () {
      let service = this.service
      if (['gab'].includes(this.service)) service = 'aab'

      return Object.keys(this.project?.display_settings?.services_wizard || {}).includes(service)
    },
    canExportService () {
      if (['api', 'app', 'control-center', 'am'].includes(this.service)) return true
      return this.$store.getters.ACL(`${this.service}ExportService`)
    },
    canImportService () {
      // TODO
      return false
    }
  },
  watch: {
    showOptions: {
      handler (val) {
        if (val) this.$emit('active-tile', this.tile)
        else this.$emit('deactive-tile', this.tile)
      }
    },
    isInstalling: {
      handler (val) {
        this.checkInstallStatus()
      }
    }
  },
  async mounted () {
    this.config = await Config()
    document.addEventListener('click', this.closeOptions)
    this.checkInstallStatus()
  },
  destroyed () {
    document.removeEventListener('click', this.closeOptions)
  },
  methods: {
    sendTrack () {
      window.localStorage.setItem('tracking-from', 'project home')
    },
    showErrorMessage () {
      if (!this.hasAccess) return
      this.$modal.show(ErrorModal, {
        error: this.error
      }, {
        height: 'auto'
      })
    },
    clickService (service) {
      if (['api', 'app'].includes(service)) {
        this.$emit('create-api-app', service)
      }
    },
    openApp (app) {
      this.$emit('open-app', app)
    },
    editApp (app) {
      this.$emit('edit-app', app)
    },
    async openOptions () {
      this.showOptions = !this.showOptions
      await this.$nextTick()
      // Check if the project options dropdown goes out of screen
      const el = this.$refs['service-options']
      if (el) {
        const rect = el.getBoundingClientRect()
        if ((rect.x + rect.width + 2) > window.innerWidth) el.style.right = '0px'
      }
    },
    closeOptions (event) {
      if (event && this.isParentOfOptions(event.target)) return
      this.showOptions = false
    },
    isParentOfOptions (target) {
      if (!this.$refs['service-options-button'] && !this.$refs['service-options']) return false
      if (target === this.$refs['service-options-button'] || target === this.$refs['service-options']) return true
      if (target.parentNode) return this.isParentOfOptions(target.parentNode)
      return false
    },
    resize (size) {
      if (!this.canUpdateProject) return
      const newSize = {}
      if (size === 's') {
        newSize.width = 1
        newSize.height = 1
      }
      if (size === 'm') {
        newSize.width = 1
        newSize.height = 2
      }
      if (size === 'l') {
        newSize.width = 2
        newSize.height = 2
      }
      this.$emit('resize-tile', this.service, newSize.width, newSize.height)
      this.$analytics.track('Change project home card’s size', {
        service: this.$t(`dashboard.${this.service}.title`)
      })
      this.showOptions = false
    },
    async removeService () {
      if (!this.canUpdateProject || !this.canRemoveService) return

      const ctx = this
      await this.$modal.show(DeleteService, {
        service: ['api', 'app'].includes(this.service) ? 'App Service' : this.$t(`home_orga.${this.service}`),
        async onSelect () {
          let service = ctx.service
          if (['api', 'app'].includes(ctx.service)) service = 'appservice'
          if (['lakehouse', 'data-catalog'].includes(ctx.service)) service = 'dm'
          if (['aab'].includes(ctx.service)) service = 'gab'
          const packageUrl = `${ctx.config.STORE}/v1/packages/project/modules/${service}/latest`
          await ctx.project.removeService(ctx.projectId, packageUrl)
          ctx.$store.dispatch('REFRESH_DATAPLANT_BY_DATAPLANT_ID', { dataplantId: ctx.projectId, ignoreCache: true })
          ctx.$emit('delete-tile', ctx.service)
        }
      }, {
        height: 'auto'
      })
    },
    async exportService () {
      if (!this.canExportService) return
      let baseUrl = ''
      if (this.service === 'dpe') baseUrl = `${this.config.DPE}/v3/configuration/export`
      if (['data-catalog', 'lakehouse'].includes(this.service)) baseUrl = `${this.config.DM}/v4/configuration/export`
      if (this.service === 'am') baseUrl = `${this.config.QUERY_ADMIN}/v3/configuration/export`
      if (this.service === 'mlm') baseUrl = `${this.config.ML}/v2/configuration/export`
      if (this.service === 'iam') baseUrl = `${this.config.IAM}/v4/backup/export?filters[applications]=true&filters[configuration]=true&filters[groups]=true&filters[mails]=true&filters[roles]=true&filters[authentication_providers]=true&filters[users]=true&filters[service_accounts]=true`

      if (baseUrl) {
        const exportUrl = baseUrl
        window.open(exportUrl, '_blank')
      }
    },
    async importService () {
      if (!this.canExportService || ['api', 'app', 'control-center'].includes(this.service)) return
      this.$refs?.importFile?.click()
    },
    async importFile (file) {
      await this.$modal.show(ImportModal, {
        classes: 'import-modal',
        onSelect: async () => {
          const fileResult = await new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.addEventListener('loadend', async () => {
              if (reader.error) return reject(reader.error)
              try {
                const res = JSON.parse(reader.result)
                resolve(res)
              } catch (err) {
                reject(err)
              }
            })
            reader.readAsText(file[0])
          })
          if (this.service === 'iam') {
            this.$api.IAM.configuration.import(fileResult)
          } else {
            let service = ''
            if (this.service === 'dpe') service = 'DPE'
            if (['data-catalog', 'lakehouse'].includes(this.service)) service = 'DM'
            if (this.service === 'am') service = 'QUERY_ADMIN'
            if (this.service === 'mlm') service = 'ML'
            this.$api[service].import(fileResult)
          }
          await this.item.import(fileResult)
        }
      }, {
        height: 'auto'
      })
    },
    setEditingMode () {
      if (!this.canUpdateProject) return
      this.$emit('set-editing-mode', true)
      this.showOptions = false
    },
    checkInstallStatus () {
      if (this.isInstalling) {
        let serviceHealth = null
        if (this.service === 'dpe') serviceHealth = this.$api.DPE
        if (['data-catalog', 'lakehouse'].includes(this.service)) serviceHealth = this.$api.DM
        if (this.service === 'am') serviceHealth = this.$api.QUERY_ADMIN
        if (this.service === 'mlm') serviceHealth = this.$api.ML
        if (this.service === 'iam') serviceHealth = this.$api.IAM
        if (this.service === 'control-center') serviceHealth = this.$api.CC
        if (this.service === 'storage-engines') serviceHealth = this.$api.STORAGE
        if (this.service === 'api' || this.service === 'app') serviceHealth = this.$api.APPSERVICE
        if (this.service === 'gab') serviceHealth = this.$api.GAB
        if (!serviceHealth) return

        this.healthCheckInterval = setInterval(async () => {
          try {
            if (this.service === 'gab') {
              const { status } = await serviceHealth.ready()
              if (!['READY'].includes(status)) throw Error('SERVICE_NOT_READY')
            } else await serviceHealth.health()
            // If health is working, clear interval
            clearInterval(this.healthCheckInterval)
            // Refresh project to get last info about services wizard
            await this.project.refresh()
            // Remove services wizard from object then redirect to the service
            const serviceRemoved = { ...this.project?.display_settings?.services_wizard }
            const serviceToRemove = this.service === 'gab' ? 'aab' : this.service
            delete serviceRemoved[serviceToRemove]
            this.project.update('display_settings.services_wizard', serviceRemoved, false)
            this.project.save()
            if (this.service === 'gab') document.location.reload()
          } catch (err) {}
        }, 5000)
      } else {
        if (this.healthCheckInterval) clearInterval(this.healthCheckInterval)
      }
    },
    setActiveTile (val) {
      if (!['api', 'app'].includes(this.service)) this.activeTile = val
    }
  }
}
</script>

<style lang="less">
  .project-dashboard-tile {
    height: 100%;
    position: relative;
    .fp-loader-container {
      left: 5px;
      top: 5px;
      right: 5px;
      bottom: 5px;
      border-radius: 8px;
    }

    .options-icon-container {
      width: 40px;
      height: 30px;
      cursor: pointer;
      display: none;
      position: absolute;
      right: 7px;
      top: -2px;
      justify-content: flex-end;
      align-items: center;

      &:hover {
        .fp4 {
          color: #0089c0;
        }
      }
      .fp4-ellipsis, .fp4-burger {
        font-size: 20px;
        color: @grey_helper;
      }
      .fp4-burger {
        display: block;
        margin-left: 10px;
        cursor: move;
      }

      .size {
        font-weight: 600;
        font-size: 13px;
        line-height: 13px;
        letter-spacing: -0.02em;
        color: #97A7B7;
        padding-left: 2.5px;
        padding-right: 2.5px;

        &.active, &:hover {
          color: #00CCF9;
          cursor: pointer;
        }

        &.disabled {
          color: rgba(62,69,80,0.65);
          cursor: not-allowed;
        }
      }
    }
    .service-options {
      position: absolute;
      right: -50px;
      top: 32px;
      background: #FFFFFF;
      box-shadow: 0 2px 10px 0 rgba(62, 69, 80, 0.25);
      border-radius: 5px;
      z-index: 1000;
      .option {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 44px;
        cursor: pointer;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #3E4550;
        position: relative;
        padding: 12px 20px;
        box-shadow: inset 0px -1px 0px #F6F9FC;

        &:first-of-type {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
        &:last-of-type {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          box-shadow: none;
        }

        i {
          position: absolute;
          left: 15px;
        }

        input[type="file"] {
          display: none;
        }

        &:hover {
          background: rgba(246, 249, 252, 0.5);
        }

        &.open {
          font-weight: 600;
          border-top: 2px solid #E4E7EC;
          border-radius: 0;
          box-shadow: inset 0px -1px 0px #F6F9FC;

          a {
            color: inherit;
            font-size: inherit;
          }
        }
        &.remove {
          color: @red;
        }
        &.disabled {
          cursor: not-allowed !important;
          color: rgba(62,69,80,0.65) !important;
        }
      }
    }

    .s-m-container {
      width: 100%;
      .s-container {
        width: 100%;
        height: 150px;
        padding: 24px 24px 0 24px;
        display: block;

        .img-error-container {
          display: flex;
          align-items: center;
          .img-container {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px;
            width: 64px;
            height: 64px;
            background: rgba(246, 249, 252, 0.5);
            border: 1px solid #E9ECF0;
            border-radius: 6px;
            margin-right: 10px;
            img {
              height: 55px;
            }
          }
          .error-container {
            padding: 5px;
            background: rgba(246, 33, 114, 0.1);
            border: 1px solid #F62172;
            border-radius: 5px;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: -0.01em;
            color: #F62172;
            width: 100%;

            .text {
              font-weight: 600;
            }

            &.installing {
              background: #eff8fb;
              border: 1px solid #0089c0;
              color: #0089c0;
            }

            &:hover:not(.installing) {
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
        .name-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 8px;
          margin-bottom: 10px;
          width: 100%;
          .name {
            font-weight: 600;
            font-size: 20px;
            line-height: 44px;
            letter-spacing: -0.02em;
            color: #3E4550;
          }
          .fp4-lock-keyhole {
            color: #97A7B7;
            font-size: 18px;
          }
        }

        &.app, &.api {
          cursor: default;
        }

        &:hover {
          .name-container {
            .name:not(.app):not(.api) {
              text-decoration: underline;
              color: #0089C0;
            }
          }
        }
      }
      .m-container {
        width: 100%;
        height: 182px;

        .buttons-container {
          padding: 0 24px;
          display: flex;

          .button-container, .left-button, .right-button {
            flex: 1 1 0px;
            .fpui-button {
              width: 100%;
              justify-content: center;
              padding: 3px;
            }
            &.left {
              margin-right: 5px;
            }
            &.right {
              margin-left: 5px;
            }
          }
        }
        .table-container {
          margin-top: 16px;
          height: 132px;
        }
        .kpi-container {
          width: calc(~"100% - 48px");
          height: 110px;
          margin: auto;
          margin-top: 20px;
          background: #F6F9FC;
          border-radius: 9px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          padding: 10px;

          .kpi {
            width: 50%;
            height: 90px;
            background: #FFFFFF;
            border: 1px solid #E4E7EC;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;

            .value {
              font-weight: 600;
              font-size: 24px;
              line-height: 17px;
              color: #0089C0;
              margin-bottom: 4px;
            }
            .text {
              font-weight: 400;
              font-size: 12px;
              line-height: 12px;
              color: #97A7B7;
              text-align: center;
              width: calc(~"100% - 10px");
            }
          }
        }
      }
    }
    .l-container {
      width: 50%;
      height: 332px;
      border-left: 1px solid #E4E7EC;
      padding-top: 24px;

      .table-container {
        height: 150px;
        .second-table {
          margin-top: 8px;
        }
      }
    }

    &.size-l {
      display: flex;

      .s-m-container {
        width: 50%;
      }
    }

    .table-container {
      .main-table, .second-table {
        position: relative;
        height: 100%;
      }

      .fpui-table {
        section {
          &.fpui-table_thead {
            >.fpui-table_tr {
              >.fpui-table_th {
                height: 28px;
                line-height: 28px;

                &:last-of-type:not(:first-of-type) {
                  padding-right: 24px;
                  .fpui-table_cell-content {
                    text-align: right;
                  }
                }

                &:nth-of-type(2) {
                  padding-left: 5px;
                }

                .fpui-table_cell-content {
                  overflow: hidden;
                }
              }
            }
          }
          &.fpui-table_body {
            height: calc(~"100% - 28px") !important;
            &.alternative-rows {
              >.fpui-table_row {
                height: 28px;

                &:nth-child(even) {
                  background-color: #FAFCFD;
                }

                >.fpui-table_cell {
                  line-height: 28px;
                  height: 23px;

                  &:nth-of-type(2) {
                    padding-right: 24px;
                    padding-left: 5px;
                    .fpui-table_cell-content {
                      text-align: right;
                    }
                  }
                  .fpui-table_cell-content {
                    .status-name-container {
                      text-overflow: ellipsis;
                      overflow: hidden;
                      white-space: nowrap;
                    }
                  }
                }
              }
              .vue-recycle-scroller__item-view {
                &:nth-child(even) {
                  background-color: #FAFCFD;
                }
              }
            }
          }
        }

        .overflow-body {
          overflow-x: hidden;
        }

        a.cell-link{
          color: inherit;
          font-size: 14px;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &.locked {
      .pointer {
        cursor: not-allowed!important;

        .name-container {
          .name {
            color: #B2BECA;
          }
        }
        &:hover {
          .name {
            color: #B2BECA !important;
            text-decoration: none !important;
          }
        }
      }
    }
    &.loading {
      .pointer {
        cursor: progress;
      }
    }

    &:hover {
      // box-shadow: 0px 0px 25px rgba(52, 65, 78, 0.2);
      .options-icon-container{
        display: flex;
      }
    }

    &.editing {
      .s-m-container {
        .s-container {
          cursor: move;

          &:hover {
            .name-container {
              .name:not(.app):not(.api) {
                text-decoration: none;
                color: inherit;
              }
            }
          }
        }
      }
    }

    &.active {
      box-shadow: 0px 4px 20px rgba(62, 69, 80, 0.4);
      border-radius: 8px;
    }
  }
</style>
