<template lang="pug">
.authenticator-setting.modal-form
  .step-wrapper.first-step.border-bottom
    .fp-row
      .fp-column.left
        .step 1
        .img-wrapper
          img(:src="require('@/core/assets/img/mfa/calque.png')" :style="{width: '87px', height: '80px'}")
      .fp-column.right
        .title {{ $t('profile.security.mfa.modal.enable_mfa.first_step.title') }}
        .text {{ $t('profile.security.mfa.modal.enable_mfa.first_step.content') }}

  .step-wrapper.second-step.border-bottom
    .fp-row
      .fp-column.left
        .step 2
        .qr-code-wrapper
          img(:src="qrCode" :style="{ width: '180px' }")
      .fp-column.right
        .title {{ $t('profile.security.mfa.modal.enable_mfa.scan_qr')}}
        .text {{ $t('profile.security.mfa.modal.enable_mfa.scan_qr.text') }}
        .text.bold {{ $t('profile.security.mfa.modal.enable_mfa.scan_qr.mfa_key') }}
        .key {{ mfaKey }}

  .step-wrapper.third-step
    .fp-row
      .fp-column.left
        .step 3
        .img-wrapper
          img(:src="require('@/core/assets/img/mfa/group.png')" :style="{width: '42px', height: '85.313px'}")
      .fp-column.right
        .title {{ $t('profile.security.mfa.modal.enable_mfa.login_with_code') }}
        .text {{ $t('profile.security.mfa.modal.enable_mfa.login_with_code.input_label') }}
        fpui-input-text(
          :value="verificationCode"
          @input="value => sendCode(value)"
          :placeholder="'000 000'"
        )
</template>

<script>

export default {
  props: {
    qrCodeInfo: { type: Object, default: () => {} }
  },
  data () {
    return {
      verificationCode: ''
    }
  },
  computed: {
    mfaKey () {
      const target = this.qrCodeInfo.secret.match(/.{1,4}/g) // Separate every 4 letters
      return target.join(' ')
    },
    qrCode () {
      return this.qrCodeInfo.qrcode
    }
  },
  methods: {
    sendCode (val) {
      this.verificationCode = val
      this.$emit('send-code', val)
    }
  }
}
</script>


<style lang="less">
.authenticator-setting {
  .step-wrapper {
    .fp-row {
      align-items: center !important;
      margin: 16px 30px !important;
      .text {
        color: #748294;
        font-size: 12px;
        letter-spacing: -0.12px;
        margin-top: 8px;
      }
      .key {
        color: @text-color;
        font-size: 12px;
        letter-spacing: -0.12px;
      }
      .left {
        display: flex;
        align-items: center;
        .step {
          color: #00CCF9;
          font-size: 12px;
          font-weight: 600;
          border-radius: 86px;
          background: #C3F4FF;
          width: 24px;
          height: 24px;
          padding: 1px 4px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-right: 8px;
        }
        .img-wrapper {
          border-radius: 5px;
          background: #F6F9FC;
          width: 195px;
          height: 104px;
          padding: 0px 24px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .qr-code-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 195px;
          height: 195px;
        }
      }
    }
  }
  .third-step {
    .text {
      margin: 8px 0;
    }
  }
}
</style>

