import { render, staticRenderFns } from "./CreateStep.vue?vue&type=template&id=181d63b2&lang=pug&"
import script from "./CreateStep.vue?vue&type=script&lang=js&"
export * from "./CreateStep.vue?vue&type=script&lang=js&"
import style0 from "./CreateStep.vue?vue&type=style&index=0&id=181d63b2&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports