<template lang="pug">
  .no-organization
    fp-loader(v-if="loading")
    .inner
      h4 {{ $t('organization.create') }}
      .form-create-orga
        label {{ $t('organization.create.name') }}
        fpui-input-text(
          color="blue"
          :placeholder="$t('organization.create.name_placeholder')"
          :value="newOrganization.name"
          @input="value => newOrganization.update('name', value, false)"
        )
      .button
        fpui-button(
          color="green"
          @click="createOrganization()"
          :disabled="!newOrganization.name"
        ) {{ $t('organization.create.valid') }}
      hr
      .organizations-container(v-if='organizationInvitations.length')
        .title-container
          .title {{ $t('profile.organizations.invitations.title') }}
        .organization-invitations
          fpui-input-search(
            :key-id="'search-invitations'"
            v-model="search.invitations"
          )
          .organizations-table(:class="{ empty: !paged.invitations.length }")
            .organization-item(
              v-for="organization in paged.invitations"
            )
              fpui-canvas-icon(
                :name="organization.name"
                :size="50"
                font-size="22px"
                :letterNumber="2"
                color="white"
                :background-color="getColor(organization.name)"
              )
              .organization-informations-container
                .organization-informations
                  .organization-name {{ organization.name }}
                  .organization-users(v-if='organization.users && organization.users.length')
                    .users-icons
                      fpui-canvas-icon(
                        v-for="(user, idx) in organizationUsers(organization)"
                        :key="idx"
                        :name="user.email"
                        :size="18"
                        :letterNumber="2"
                        color="white"
                        :background-color="getColor(user.email)"
                        type="circle"
                      )
                    .users-count {{ $t('organization_selector.members', [organization.users.length], organization.users.length) }}
                .organization-actions
                  fpui-button(
                    color='blue-flash'
                    auto-width
                    @click="acceptInvitation(organization)"
                  ) {{ $t('profile.organizations.invitations.join') }}
                  fpui-button(
                    color='white'
                    auto-width
                    @click="discardInvitation(organization)"
                  ) {{ $t('profile.organizations.invitations.discard') }}
            fp-pagination(
              :key='organizationsInvitationsFiltered.length'
              :items='organizationsInvitationsFiltered'
              @page-changed="(page) => paged.invitations = page.items"
            )
          .organizations-empty-paged(v-if='!paged.invitations.length')
            span {{ $t('profile.preferences.invites_received_no_search') }}
</template>

<script>
import TermsConditionsModal from '@/shared/components/modals/TermsConditions'

export default {
  data () {
    return {
      loading: false,
      search: {
        invitations: ''
      },
      paged: {
        invitations: []
      },
      newItem: this.$api.ORGANIZATION.organizations.new()
    }
  },
  computed: {
    newOrganization () {
      return this.newItem
    },
    currentUser () {
      return this.$store.getters.SESSION
    },
    organizationInvitations () {
      return this.$store.getters.INVITING_ORGANIZATIONS || []
    },
    organizationsInvitationsFiltered () {
      if (this.search.invitations !== '') {
        return this.organizationInvitations.filter(o => {
          return o.name.toLowerCase().indexOf(this.search.invitations.toLowerCase()) !== -1
        })
      }
      return this.organizationInvitations
    }
  },
  async mounted () {
    if (!this.currentUser?.tc_accepted_at) {
      try {
        if (await TermsConditionsModal(this)) {
          await this.$store.dispatch('ACCEPT_TERMS_AND_CONDITIONS')
        } else {
          this.$fpuiMessageBlock.info(this.$t('fpui-modal.tc.must_accept'))
          return this.$router.push('/')
        }
      } catch (error) {
        this.$fpuiMessageBlock.error(error?.message)
      }
    }
    if (!this.$store.getters.INVITING_ORGANIZATIONS.length) await this.$store.dispatch('LOAD_INVITING_ORGANIZATIONS')
  },
  methods: {
    async createOrganization () {
      this.loading = true
      try {
        // Create orga in back
        const response = await this.newOrganization.create(this.newOrganization.name)
        // Refresh organizations
        await this.$store.dispatch('LOAD_ORGANIZATIONS')
        await this.$store.dispatch('REFRESH_CURRENT_USER')
        // redirect to new orga
        this.$router.push(`/home/${response.id}`)
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
      }
      this.loading = false
    },
    getColor (txt) {
      const colors = ['#FFCD2F', '#f62172', '#0050d7', '#81cc28']
      return colors[txt.length % colors.length]
    },
    async acceptInvitation (organization) {
      this.innerLoading = true
      try {
        await this.$api.ORGANIZATION.organizations.userJoinsFromProfile(organization._id)
        this.$fpuiMessageBlock.pop('success', this.$t('profile.organizations.invitations.join.success'))
        await this.$store.dispatch('LOAD_ORGANIZATIONS', true)
        // If no organization active, we set it when request so the user can see the status in the selector
        this.$store.commit('REMOVE_INVITING_ORGANIZATION', organization._id)
      } catch (err) {
        this.$fpuiMessageBlock.pop('error', err.message)
        console.error(err)
      } finally {
        this.innerLoading = false
      }
    },
    async discardInvitation (organization) {
      this.innerLoading = true
      try {
        await this.$api.ORGANIZATION.organizations.userRejectJoinFromProfile(organization._id)
        this.$store.commit('REMOVE_INVITING_ORGANIZATION', organization._id)
        await this.$store.dispatch('LOAD_AVAILABLE_ORGANIZATIONS')
      } catch (err) {
        this.$fpuiMessageBlock.pop('error', err.message)
        console.error(err)
      } finally {
        this.innerLoading = false
      }
    }
  }
}
</script>

<style lang="less">
.no-organization {
  display: flex;
  justify-content: center;
  height: 100%;
  background-image: url('./assets/organization@2x.png');
  background-position: 50% 50px;
  background-repeat: no-repeat;
  background-size: 682px 256px;

  h4 {
    font-size: 32px !important;
    font-weight: bold !important;
    letter-spacing: 0;
    line-height: 40px;
    color: #3E4550;
    margin-bottom: 21px;
    margin-top: 295px !important;
  }
  .form-create-orga {
    label {
      font-size: 12px;
      letter-spacing: 0;
      line-height: 45px;
      color: #97a7b7;
      font-weight: normal;
      padding: 0;
    }
    .fpui-input-text {
      margin-top: -10px;
    }
  }

  .button {
    display: flex;
    justify-content: center;
    .fpui-button {
      width: 124px;
      margin-top: 44px;
    }
  }

  hr {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .organizations-container {
    padding-bottom: 20px;
    .title-container {
      display: flex;
      margin: 15px 0;
      .title {
        font-size: 12px;
        color: @blue;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
    .organization-invitations {
      .organizations-table {
        margin-top: 15px;
        border-radius: 5px;
        &:not(.empty) {
          border: 1px solid #E4E7EC;
        }
        .organization-item {
          display: flex;
          gap: 10px;
          text-decoration: none;
          padding: 10px 20px;
          &:hover {
            background-color: #fafafb;
          }
          &:not(:nth-last-child(-n + 2)) {
            border-bottom: 1px solid #E4E7EC;
          }
          .organization-informations-container {
            display: flex;
            flex: 0 1 100%;
            justify-content: space-between;
            align-items: center;
            .organization-informations {
              display: flex;
              flex-direction: column;
              .organization-name {
                color: #3E4550;
                font-size: 13px;
                font-weight: 600;
              }
              .organization-users {
                display: flex;
                align-items: center;
                .users-icons {
                  display: flex;
                  flex-direction: row-reverse;
                  .fpui-canvas-icon {
                    &:not(:first-child) {
                      margin-right: -3px;
                    }
                    canvas {
                      border: 1.5px solid white;
                    }
                  }
                }
                .users-count {
                  color: #748294;
                  font-size: 10px;
                  font-weight: 400;
                  line-height: 20px;
                  margin-left: 5px;
                }
              }
            }
            .organization-actions {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: center;
              align-items: center;
              color: #97a6b7;
              gap: 11px;
            }
          }
        }
      }
      .fp-pagination {
        padding: 10px 0;
        border-top: 1px solid #E4E7EC;
      }
      &.empty {
        > span {
          color: #B2BECA;
          font-size: 12px;
        }
      }
      > .organizations-empty-paged {
        color: #B2BECA;
        font-size: 12px;
        text-align: center;
      }
    }
  }
}
</style>
