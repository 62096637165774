<template lang="pug">
.home
  organization-selector-header
  organization-selector(v-if='displayOrganizationSelector')
</template>

<script>
import OrganizationSelectorHeader from '@/core/components/Home/OrganizationSelectorHeader'
import OrganizationSelector from '@/core/components/Home/OrganizationSelector'

export default {
  components: { OrganizationSelector, OrganizationSelectorHeader },
  computed: {
    organizations () {
      return this.$store.getters.ORGANIZATIONS
    },
    availableOrganizations () {
      return this.$store.getters.AVAILABLE_ORGANIZATIONS || []
    },
    currentUser () {
      return this.$store.getters.SESSION
    },
    displayOrganizationSelector () {
      if (this.organizations?.length && !localStorage.getItem('ACTIVE_ORGANIZATION')) return true
      return false
    }
  },
  watch: {
    organizations () {
      this.redirectOrganization()
    }
  },
  async mounted () {
    if (!this.$store.getters.AVAILABLE_ORGANIZATIONS.length) await this.$store.dispatch('LOAD_AVAILABLE_ORGANIZATIONS')
    await this.redirectOrganization()
  },
  methods: {
    async redirectOrganization () {
      if (localStorage.getItem('ACTIVE_ORGANIZATION')) return this.$router.push(`/home/${localStorage.getItem('ACTIVE_ORGANIZATION')}`)
      if (this.organizations?.length) return
      if (!this.$route.query.bypassTeammates && this.availableOrganizations?.length) this.$router.push('/home/teammates')
      else if (!this.organizations?.length) this.$router.push('/home/new')
    }
  }
}
</script>

<style lang="less">

</style>
