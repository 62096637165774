<template lang='pug'>
.fp-header
  .left.organization-selector-header
    img.logo(:src="require('@/shared/assets/img/ovhcloud_logo_blue.svg')")
  .right
    .actions
      button.button.button-lang
        .current {{ lang }}
        ul.dropdown
          li.lang(
            :class="{ active: isActiveLang(l) }"
            @click="setLang(l)"
            v-for="l in locales"
          ) {{ $t(l) }}
      button.button.button-help
        i.fp4.fp4-circle-info-small
        ul.dropdown
          li.docs
            a(:href="links.docs" target="blank" v-if="links.docs")
              i.fp4.fp4-book
              span {{ $t('header.docs') }}
          li.status(v-if="!isDataPlatform")
            a(:href="links.status" target="blank" v-if="links.status")
              i.fp4.fp4-heart-pulse
              span {{ $t('header.status') }}
          li.changelogs
            a(href="https://docs.forepaas.io/#/en/changelog" target="blank")
              i.fp4.fp4-arrows-rotate
              span {{ $t('header.changelog') }}
          li.roadmap(v-if="!isDataPlatform")
            a(href="#/features" target="blank")
              i.fp4.fp4-rocket
              span {{ $t('header.roadmap') }}
          li.support-link(v-if="joinableOrganization")
            a(:href="supportLink" target="blank")
              i.fp4.fp4-life-ring
              span {{ $t('header.support') }}
          li.discord-link
            a(:href="ovhDiscordUrl" target="blank")
              img(:src="require('@/shared/assets/img/discord.svg')")
              span {{ $t('header.discord') }}
      button.button.button-user
        img.profile-picture(:src="picture")
        ul.dropdown
          li.profile-container
            a(:href="'#/user'")
              .profile-info(v-if="picture")
                img.profile-picture(:src="picture")
                span {{ user.firstname }} {{ user.lastname }}
              .profile-info(v-else)
                i.fp4.fp4-circle-user
                span {{ $t('header.my_profile') }}
          li.account
            a(href="#/accounts")
              span {{ $t('header.my_account') }}
          li.logout
            span(@click="logout") {{ $t('header.logout') }}
</template>

<script>
import moment from 'moment'
import Config from '@/shared/Config'
import OrganizationProjectSelector from '@/shared/components/app/Header/OrganizationProjectSelector.vue'
import ServiceSelector from '@/shared/components/app/Header/ServiceSelector.vue'
import { ovhSupportUrl, ovhDiscordUrl } from '@/shared/store/constants/supportUrl'

export default {
  components: {
    OrganizationProjectSelector,
    ServiceSelector
  },
  data () {
    return {
      config: null,
      pageWithoutLogo: [
        { name: 'organization', title: 'header.title_organization', icon: 'fp4-organization-header-circle' },
        { name: 'changelog', title: 'header.title_changelog', icon: 'fp4-list' },
        { name: 'user', title: 'header.title_profile', icon: 'fp4-user-circles' },
        { name: 'accounts', title: 'header.title_accounts', icon: 'fp4-money-card-circle' },
        { name: 'support', title: 'header.title_support', icon: 'fp4-life-ring' },
        { name: 'services', title: 'header.title_services', icon: 'fp4-life-ring' },
        { name: 'infra', title: 'header.title_infra', image: require('@/core/components/Dataplant/assets/cc_dark_no_background.svg') },
        { name: 'qb', title: 'header.title_qb', image: require('@/core/components/Dataplant/assets/qb_dark_no_background.svg') },
        { name: 'app', title: 'header.title_app', image: require('@/core/components/Dataplant/assets/app_dark_no_background.svg') },
        { name: 'api', title: 'header.title_api', image: require('@/core/components/Dataplant/assets/api_dark_no_background.svg') }
      ],
      picture: require('@/core/assets/img/default_avatar.png'),
      ovhDiscordUrl: ovhDiscordUrl
    }
  },
  computed: {
    isDataPlatform () {
      return this.config?.DATA_PLATFORM_ACCESS
    },
    user () {
      return this.$store.getters.SESSION
    },
    locales () { return this.$i18n.locales() },
    lang () { return this.$i18n.locale().toUpperCase() },
    links () {
      return {
        docs: this.config?.DOCS,
        status: this.config?.STATUS
      }
    },
    organization () {
      return this.$store.getters.ACTIVE_ORGANIZATION(this.$route.params)
    },
    joinableOrganization () {
      return this.requestToJoinPending ? this.$store.getters.ORGANIZATIONS.find(orga => !orga.pending_request) : this.organization
    },
    requestToJoinPending () {
      return this.organization?.pending_request
    },
    supportLink () {
      return this.config?.DATA_PLATFORM_ACCESS ? ovhSupportUrl : `#/support/${this.joinableOrganization._id}`
    }
  },
  async mounted () {
    moment.locale(this.lang)
    this.config = await Config()
  },
  methods: {
    setLang (lang) {
      this.$i18n.set(lang)
      moment.locale(lang)
      window.localStorage.setItem('hq_lang', lang)
    },
    isActiveLang (lang) {
      return lang === this.lang.toLowerCase()
    },
    logout () {
      this.$store.commit('SET_OPEN_SIDE_BAR', false)
      this.$store.dispatch('LOGOUT_CMP')
    }
  }
}
</script>

<style lang='less'>
// Style in src/shared/styles/header.less

.fp-header {
  .left {
    &.organization-selector-header {
      img.logo {
        width: 100px;
        height: auto;
      }
    }
  }
}
</style>
