<template lang='pug'>
.fp-modal-terms-conditions
  fpui-modal
    fpui-modal-header(
      :title="$t('fpui-modal.tc.title')"
      @close='$emit("close")'
    )
    fpui-modal-body
      .modal-content
        .fp-container
          .fp-box
            .box-content
              .tc-box(v-for='(tc, idx) in items')
                .checkbox-content
                  fpui-input-checkbox(
                    :value="tc.accepted"
                    @input="(val) => updateTc(tc.id, idx, val)"
                  )
                  span {{ $t('fpui-modal.tc.content.' + tc.id) }}
                fpui-button(
                  icon='fp4 fp4-down-to-line'
                  icon-only
                  reverse
                  noshadow
                  unbordered
                  color='grey'
                  @click="download(tc)"
                )
    fpui-modal-footer(:buttons="buttons")
</template>

<script>
export default {
  props: {
    resolve: { type: Function, required: true }
  },
  data () {
    return {
      items: [{
        id: 'legal-age',
        accepted: false,
        links: {
          en: 'https://storage.gra.cloud.ovh.net/v1/AUTH_325716a587c64897acbef9a4a4726e38/contracts/4789dde-contrat_genServices-IE-7.0.pdf',
          fr: 'https://storage.gra.cloud.ovh.net/v1/AUTH_325716a587c64897acbef9a4a4726e38/contracts/2f391d2-contrat_genServices-FR-14.1.pdf'
        }
      }, {
        id: 'ovhcloud-special',
        accepted: false,
        links: {
          en: 'https://storage.gra.cloud.ovh.net/v1/AUTH_325716a587c64897acbef9a4a4726e38/contracts/fd80c12-contrat_partSERVICETEST-IE-1.0.pdf',
          fr: ' https://storage.gra.cloud.ovh.net/v1/AUTH_325716a587c64897acbef9a4a4726e38/contracts/8d93763-contrat_partSERVICETEST-FR-1.0.pdf'
        }
      }, {
        id: 'aab-special',
        accepted: false,
        links: {
          en: 'https://storage.gra.cloud.ovh.net/v1/AUTH_325716a587c64897acbef9a4a4726e38/contracts/6c46348-contrat_partAIAPPBUILDER-IE-1.0.pdf',
          fr: 'https://storage.gra.cloud.ovh.net/v1/AUTH_325716a587c64897acbef9a4a4726e38/contracts/613c3b2-contrat_partAIAPPBUILDER-FR-1.0.pdf'
        }
      }, {
        id: 'generative-ai-special',
        accepted: false,
        links: {
          en: 'https://storage.gra.cloud.ovh.net/v1/AUTH_325716a587c64897acbef9a4a4726e38/contracts/503ad73-regles_utilisation_IAGEN_OVH-IE-1.0.pdf',
          fr: 'https://storage.gra.cloud.ovh.net/v1/AUTH_325716a587c64897acbef9a4a4726e38/contracts/b18adf8-regles_utilisation_IAGEN_OVH-FR-1.0.pdf'
        }
      }]
    }
  },
  computed: {
    buttons () {
      return [
        {
          text: this.$t('fpui-modal.tc.content.buttons.decline'),
          color: 'white',
          type: 'close',
          handler () {
            this.$emit('close')
            return this.resolve(false)
          }
        },
        {
          text: this.$t('fpui-modal.tc.content.buttons.accept'),
          color: 'blue-flash',
          autoWidth: true,
          disabled: () => {
            return this.items.some(tc => Object.values(tc).includes(false))
          },
          handler () {
            this.$emit('close')
            return this.resolve(true)
          }
        }
      ]
    }
  },
  methods: {
    download (tc) {
      const tcLanguages = Object.keys(tc.links)
      if (tcLanguages.includes(this.$i18n.locale())) return window.open(tc.links[this.$i18n.locale()], '_blank')
      return window.open(tc.links.en, '_blank') // Fallback to the en language
    },
    updateTc (tc, idx, val) {
      this.$set(this.items, idx, { ...this.items[idx], accepted: val })
    }
  }
}
</script>

<style lang='less'>
.fp-modal-terms-conditions {
  .modal-body {
    background-color: #F6F9FC;
    padding: 20px;
    .fp-container {
      margin-top: 0;
      margin-bottom: 0;
      .fp-box {
        padding: 20px 8px 20px 20px;
      }
    }
    .tc-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      .checkbox-content {
        display: flex;
        align-items: center;
        border-radius: 5px;
        border: 1px solid #E4E7EC;
        padding: 5px 20px 10px 20px;
        flex: 1 1 auto;
      }
    }
  }
  .modal-footer {
    .footer-actions {
      justify-content: space-between;
    }
  }
}
</style>
